import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CloseFullscreen } from '../../common/icons/closeFullscreen.svg';
import { ReactComponent as OpenInFull } from '../../common/icons/openInFull.svg';
import { ReactComponent as DealValue } from '../../common/icons/request_quote.svg';

import {
  ChangeCircleRounded,
  CheckCircleRounded,
  CloseRounded,
  ContentCopyRounded,
  Flag,
  LabelOutlined,
  MailOutlineRounded,
  ModeEditOutlineOutlined,
  PeopleOutlineRounded,
  PhoneOutlined,
  SavingsOutlined,
  TaskRounded,
  ViewTimelineOutlined,
  VisibilityOffRounded,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import ConfirmCopy from '../../common/ConfirmCopy';
import { stageVariant } from '../../common/CustomChip';
import CustomDropMenu from '../../common/CustomDropMenu';
import DetailsNavigation from '../../common/DetailsNavigation';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import Logo from '../../common/Logo';
import PopupMessage from '../../common/PopupMessage';
import ShareLink from '../../common/ShareLink';
import StyledIcon from '../../common/StyledIcon';
import { appSlice } from '../../store/appSlice';
import { isBackofficePage } from '../../utils/functions';
import Header from '../Header';
import DealClosedModal from './DealClosedModal';
import DealValueEditor from './DealValueEditor';
import EditDealClosedModal from './EditDealClosedModal';
import InteractionEmployeeContacts from './InteractionEmployeeContacts';
import InteractionLogs from './InteractionLogs';
import InteractionsMoreMenu from './InteractionsMoreMenu';
import ZipWorkflowLink from './ZipWorkflowLink';

const lineStyle = {
  background: '#DEDBEC',
  width: '100%',
  height: '2px',
};

const InteractionDetails = ({ close, fullscreen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { page, user, search, previousSearch, dealValueRanges } = useSelector(
    (state) => state.appSlice,
  );
  const {
    addPreviousDetailsNavigation,
    setNextDetailsNavigation,
    setPage,
    setSearch,
    setPreviousSearch,
  } = appSlice.actions;

  const [interaction, setInteraction] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openMailId, setOpenMailId] = useState(null);
  const [openPhoneId, setOpenPhoneId] = useState(null);
  const [engagementStage, setEngagementStage] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isOpenDealClosed, setIsOpenDealClosed] = useState(false);
  const [isEditDealClosed, setIsEditDealClosed] = useState(false);
  const [confirmCopy, setConfirmCopy] = useState('id + variant');
  const [requestTickets, setRequestTickets] = useState(null);

  const [isEditingDealValue, setIsEditingDealValue] = useState(false);

  const updateDealValue = async (newDealValue) => {
    updateInteraction(
      {
        assigned_deal_value: newDealValue,
      },
      interaction.id,
    ).then((res) => {});
  };

  const handleConfirmCopy = (id, variant) => {
    setConfirmCopy(`${id} ${variant}`);
    setTimeout(() => {
      setConfirmCopy('');
    }, 1000);
  };

  const handleMailPopoverOpen = (id) => {
    setOpenMailId(id);
  };

  const handleMailPopoverClose = () => {
    setOpenMailId(null);
  };
  const handlePhonePopoverOpen = (id) => {
    setOpenPhoneId(id);
  };

  const handlePhonePopoverClose = () => {
    setOpenPhoneId(null);
  };

  const handleDetailsNavigation = () => {
    dispatch(
      addPreviousDetailsNavigation(`${location.pathname}${location.search}`),
    );
    dispatch(setNextDetailsNavigation([]));
  };

  const filterInternalStakeholders = (res) => {
    return res.departments_involved
      .map((item) => ({
        type: item,
        items: res.internal_stakeholders.filter(
          (internal) => internal.department === item,
        ),
      }))
      .filter((item) => item.items.length !== 0);
  };

  const findRequestTickets = (requestTickets) => {
    let condition =
      requestTickets && Array.isArray(requestTickets) && requestTickets.length;

    if (condition) return requestTickets;
    return null;
  };

  const fetchData = async () => {
    const data = await getData();
    if (data !== 'error') {
      setRequestTickets(findRequestTickets(data.request_tickets));
      setInteraction({
        ...data,
        internal_stakeholders: filterInternalStakeholders(data),
      });
      setEngagementStage(data.stage_name);
    }
  };

  useEffect(() => {
    document.title = 'PartnerElement | Details > Interaction details';
    fullscreen && window.scrollTo(0, 0);
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  }, [id, fullscreen]);

  const getData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/interactions/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const updateInteraction = async (interaction, id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/interactions/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(interaction),
      },
    );
    if (response.ok) {
      const data = await response.json();
      setInteraction({
        ...data,
        internal_stakeholders: filterInternalStakeholders(data),
      });
      setEngagementStage(data.stage_name);
    } else {
      return 'error';
    }
  };

  const linkUrl =
    page === 'overview'
      ? `/details/supplier/${interaction?.supplier_id}${
          fullscreen ? '/fullscreen' : ''
        }`
      : `/${page}/details/supplier/${interaction?.supplier_id}${
          fullscreen ? '/fullscreen' : ''
        }${location.search}`;

  if (isLoading && !interaction) {
    return <LoadingSkeleton variant={'interactionDetails'} close={close} />;
  }

  return (
    <Box>
      {fullscreen && <Header handleOnClick={close} />}
      <Box
        sx={{
          maxWidth: fullscreen ? '100%' : '768px',
          overflowY: 'auto',
          padding: '16px 0 0 0',
          background: 'white',
          '@media (max-width: 768px)': {
            width: 'calc(100vw - 48px)',
          },
        }}
      >
        {interaction && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1.5px solid #694FA31A',
                paddingLeft: fullscreen ? '40px' : '24px',
                paddingRight: fullscreen ? '24px' : '24px',
                paddingBottom: '16px',
                marginBottom: '24px',
              }}
            >
              <DetailsNavigation handleClose={close} />
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.subtitle1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  paddingLeft: '8px',
                  flexGrow: '1',
                }}
              >
                <Link
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.subtitle1}
                  sx={{ cursor: 'pointer', fontWeight: '500' }}
                  underline="none"
                  onClick={() => {
                    dispatch(setPage('interactions'));
                    window.scrollTo(0, 0);
                    close();
                    navigate('/interactions');
                  }}
                >
                  Interactions
                </Link>
                <Box color={(theme) => theme.palette.text.secondary}>
                  {' '}
                  / {interaction?.uid}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <ShareLink />
                {!isBackofficePage(location) && (
                  <IconButton
                    type="button"
                    onClick={() => {
                      if (fullscreen) {
                        dispatch(setSearch(previousSearch));
                        dispatch(setPreviousSearch(''));
                      } else {
                        dispatch(setPreviousSearch(search));
                        dispatch(setSearch(''));
                      }
                      handleDetailsNavigation();
                      navigate(
                        `${
                          page === 'overview' ? '' : `/${page}`
                        }/details/interaction/${id}${
                          fullscreen ? '' : '/fullscreen'
                        }`,
                      );
                    }}
                  >
                    <StyledIcon
                      component={fullscreen ? CloseFullscreen : OpenInFull}
                    />
                  </IconButton>
                )}
                <InteractionsMoreMenu
                  interaction={interaction}
                  engagementStage={engagementStage}
                  updateInteraction={updateInteraction}
                  setPopupMessage={setPopupMessage}
                  setIsOpenDealClosed={setIsOpenDealClosed}
                />
                <IconButton type="button" onClick={close}>
                  <CloseRounded sx={{ color: '#2F236D73', fontSize: 24 }} />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                padding: fullscreen ? '16px 24px 0 40px' : '0 24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '16px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                  }}
                >
                  <Logo size={44} img={interaction.supplier_image_url} />
                  <Box
                    sx={{
                      lineHeight: '160%',
                    }}
                    fontSize={(theme) => theme.typography.h6}
                  >
                    {interaction?.uid}
                  </Box>
                  <Link
                    underline="none"
                    fontSize={(theme) => theme.typography.subtitle2}
                    sx={{
                      cursor: 'pointer',
                    }}
                    color="secondary"
                    onClick={() => {
                      handleDetailsNavigation();
                      navigate(linkUrl, {
                        state: { interactionId: id, page },
                      });
                    }}
                  >
                    View supplier
                  </Link>
                </Box>
                {requestTickets && requestTickets.length ? (
                  <ZipWorkflowLink requestTickets={requestTickets} />
                ) : null}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderBottom: '1.5px solid #694FA31A',
                  paddingBottom: '16px',
                  marginBottom: '16px',
                }}
              >
                {interaction?.procurement_involved && (
                  <Box
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.body1}
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRounded
                      color={'icon_success'}
                      sx={{ fontSize: 24 }}
                    />
                    Procurement engaged
                  </Box>
                )}
                {interaction?.renewal && (
                  <Box
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.body1}
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <StyledIcon
                      component={ChangeCircleRounded}
                      color="icon_warning"
                    />
                    Renewal
                  </Box>
                )}
                {interaction?.deal_closed && (
                  <Box
                    color={(theme) => theme.palette.secondary.main}
                    fontSize={(theme) => theme.typography.body1}
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <StyledIcon component={TaskRounded} color={'secondary'} />
                    Deal closed
                  </Box>
                )}
                {interaction?.deal_closed ? (
                  <Box>
                    <Box
                      sx={{
                        marginBottom: '8px',
                        background: '#694FA31A',
                        height: '1.5px',
                        width: '100%',
                      }}
                    />
                    <Box>
                      <Stack direction={'row'} alignItems={'start'}>
                        <Stack
                          width={'100%'}
                          direction={'column'}
                          alignItems={'center'}
                          marginBottom={'16px'}
                        >
                          <Box
                            fontSize={(theme) => theme.typography.subtitle1}
                            color={(theme) => theme.palette.text.main}
                          >
                            {moment(interaction?.deal_closed_at)
                              .utc()
                              .diff(
                                moment(interaction?.start_time),
                                'days',
                              )}{' '}
                            days
                          </Box>
                          <Box
                            fontSize={(theme) => theme.typography.body2}
                            color={(theme) => theme.palette.text.medium}
                          >
                            Deal closure time
                          </Box>
                        </Stack>
                        <Box sx={{ position: 'relative' }}>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: '0',
                              left: '-40px',
                            }}
                            padding={'8px'}
                            onClick={() => setIsEditDealClosed(true)}
                          >
                            <StyledIcon
                              component={ModeEditOutlineOutlined}
                              color={'icon'}
                            />
                          </IconButton>
                        </Box>
                      </Stack>
                      {interaction?.procurement_involved ? (
                        <Box marginBottom={'24px'}>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ paddingX: '55px' }}
                          >
                            <StyledIcon component={Flag} color={'icon'} />
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              width={'100%'}
                              gap={'4px'}
                            >
                              <Box sx={lineStyle} />
                            </Stack>
                            <StyledIcon
                              component={CheckCircleRounded}
                              color={'icon_success'}
                            />
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              width={'100%'}
                              gap={'4px'}
                            >
                              <Box sx={lineStyle} />
                            </Stack>
                            <StyledIcon
                              component={TaskRounded}
                              color={'secondary'}
                            />
                          </Stack>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            paddingRight={'30px'}
                          >
                            <Box
                              sx={{ width: 'fit-content', textAlign: 'center' }}
                            >
                              <Box
                                fontSize={(theme) => theme.typography.body2}
                                color={(theme) => theme.palette.text.main}
                              >
                                Interaction started
                              </Box>
                              <Box
                                fontSize={(theme) => theme.typography.caption}
                                color={(theme) => theme.palette.text.medium}
                              >
                                {moment(interaction?.start_time).format(
                                  'MMM, DD',
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{ width: 'fit-content', textAlign: 'center' }}
                            >
                              <Box
                                fontSize={(theme) => theme.typography.body2}
                                color={(theme) => theme.palette.text.main}
                              >
                                Procurement engaged
                              </Box>
                              <Box
                                fontSize={(theme) => theme.typography.caption}
                                color={(theme) => theme.palette.text.medium}
                              >
                                {moment(
                                  interaction?.procurement_involved_at,
                                ).format('MMM, DD')}
                              </Box>
                            </Box>
                            <Box
                              sx={{ width: 'fit-content', textAlign: 'center' }}
                            >
                              <Box
                                fontSize={(theme) => theme.typography.body2}
                                color={(theme) => theme.palette.text.main}
                              >
                                Deal closed
                              </Box>
                              <Box
                                fontSize={(theme) => theme.typography.caption}
                                color={(theme) => theme.palette.text.medium}
                              >
                                {moment(interaction?.deal_closed_at).format(
                                  'MMM, DD',
                                )}
                              </Box>
                            </Box>
                          </Stack>
                        </Box>
                      ) : (
                        <Box marginBottom={'24px'}>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ paddingX: '55px' }}
                          >
                            <StyledIcon component={Flag} color={'icon'} />
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              width={'100%'}
                              gap={'4px'}
                            >
                              <Box sx={lineStyle} />
                            </Stack>
                            {/*<Box
                            sx={{ whiteSpace: "nowrap", paddingX: "4px" }}
                            fontSize={(theme) => theme.typography.body2}
                            color={(theme) => theme.palette.text.medium}
                          >
                            {moment()
                              .utc()
                              .diff(
                                moment(interaction?.start_time),
                                "days"
                              )}{" "}
                            days
                          </Box>*/}
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              width={'100%'}
                              gap={'4px'}
                            >
                              <Box sx={lineStyle} />
                            </Stack>
                            <StyledIcon
                              component={TaskRounded}
                              color={'secondary'}
                            />
                          </Stack>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            paddingRight={'30px'}
                          >
                            <Box
                              sx={{ width: 'fit-content', textAlign: 'center' }}
                            >
                              <Box
                                fontSize={(theme) => theme.typography.body2}
                                color={(theme) => theme.palette.text.main}
                              >
                                Interaction started
                              </Box>
                              <Box
                                fontSize={(theme) => theme.typography.caption}
                                color={(theme) => theme.palette.text.medium}
                              >
                                {moment(interaction?.start_time).format(
                                  'MMM, DD',
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{ width: 'fit-content', textAlign: 'center' }}
                            >
                              <Box
                                fontSize={(theme) => theme.typography.body2}
                                color={(theme) => theme.palette.text.main}
                              >
                                Deal closed
                              </Box>
                              <Box
                                fontSize={(theme) => theme.typography.caption}
                                color={(theme) => theme.palette.text.medium}
                              >
                                {moment(interaction?.deal_closed_at).format(
                                  'MMM, DD',
                                )}
                              </Box>
                            </Box>
                          </Stack>
                        </Box>
                      )}
                      <Stack direction={'row'} gap={'8px'} alignItems={'start'}>
                        <StyledIcon
                          component={SavingsOutlined}
                          color={'icon'}
                        />
                        <Box>
                          <Box
                            color={(theme) => theme.palette.text.main}
                            fontSize={(theme) => theme.typography.subtitle1}
                          >
                            {interaction?.savings_amount &&
                              interaction?.savings_percentage &&
                              `${interaction?.savings_amount.toLocaleString(
                                'en-US',
                                {
                                  style: 'currency',
                                  currency: 'USD',
                                },
                              )} (${(
                                interaction?.savings_percentage / 100
                              ).toLocaleString('en-US', {
                                style: 'percent',
                              })})`}{' '}
                            <Box
                              component={'span'}
                              color={(theme) => theme.palette.text.medium}
                              fontSize={(theme) => theme.typography.body1}
                            >
                              Savings
                            </Box>
                          </Box>
                          {interaction?.deal_closure_notes && (
                            <Box
                              marginTop={'6px'}
                              color={(theme) => theme.palette.text.medium}
                              fontSize={(theme) => theme.typography.body2}
                            >
                              {interaction?.deal_closure_notes}
                            </Box>
                          )}
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body1}
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <ViewTimelineOutlined
                        sx={{ fontSize: 24, color: '#2F236D73' }}
                      />
                      Stage
                    </Box>
                    <Chip
                      label={engagementStage}
                      size={'small'}
                      sx={{
                        padding: '4px 10px',
                        span: { padding: 0 },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        ...stageVariant(engagementStage),
                      }}
                    />
                  </Box>
                )}

                {interaction?.deprioritization_reason && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                    }}
                  >
                    <StyledIcon
                      component={VisibilityOffRounded}
                      color="icon_error"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        fontSize: '16px',
                        color: '#C62828',
                        alignItems: 'end',
                      }}
                    >
                      <Box>Deprioritized:</Box>
                      <Box
                        sx={{ height: 'fit-content', verticalAlign: 'end' }}
                        color={(theme) => theme.palette.text.medium}
                        fontSize={(theme) => theme.typography.body2}
                      >
                        {interaction?.deprioritization_reason}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={{ marginBottom: '24px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'start',
                    marginBottom: '16px',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                  >
                    <StyledIcon component={LabelOutlined} color="icon" />
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body1}
                    >
                      Keywords
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    {interaction?.keywords
                      .filter((item) => item.stage_name === engagementStage)
                      .map((item, index) => (
                        <Chip
                          size={'small'}
                          key={index}
                          variant={'filled'}
                          sx={{
                            span: { padding: 0 },
                            padding: '4px 10px',
                            color: '#000000DE',
                            fontSize: '13px',
                          }}
                          label={item.keyword}
                        />
                      ))}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '6px', marginBottom: '16px' }}>
                  <StyledIcon component={MailOutlineRounded} color="icon" />
                  <Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body1}
                      sx={{
                        marginBottom: '6px',
                      }}
                    >
                      {interaction?.num_emails} messages over{' '}
                      {interaction?.num_days} days
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {moment(new Date(interaction?.start_time)).format(
                        'MMM DD, YYYY',
                      )}
                      {' - '}
                      {moment(new Date(interaction?.end_time)).format(
                        'MMM DD, YYYY',
                      )}
                    </Box>
                  </Box>
                </Box>

                {dealValueRanges && dealValueRanges?.length ? (
                  <Box
                    sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}
                  >
                    <DealValue width="24px" />
                    <Box>
                      <Box
                        component={'span'}
                        color={(theme) => theme.palette.text.medium}
                      >
                        <DealValueEditor
                          dealValue={interaction.deal_value}
                          dealValueRanges={dealValueRanges}
                          isAssignedDealValue={
                            interaction.deal_value_manually_assigned
                          }
                          onSave={(newDealValue) =>
                            updateDealValue(newDealValue)
                          }
                          onCancel={() => console.log('Edit cancelled')}
                          isEditing={isEditingDealValue}
                          setIsEditing={setIsEditingDealValue}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  ''
                )}

                <Box sx={{ display: 'flex', gap: '6px' }}>
                  <StyledIcon component={PeopleOutlineRounded} color="icon" />
                  <Box>
                    <Box
                      component={'span'}
                      color={(theme) => theme.palette.text.main}
                      sx={{
                        marginRight: '4px',
                      }}
                      fontSize={(theme) => theme.typography.subtitle1}
                    >
                      {interaction?.num_stakeholders} people
                    </Box>
                    <Box
                      component={'span'}
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body1}
                    >
                      involved
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ maxWidth: fullscreen ? '928px' : '100%' }}>
                <InteractionEmployeeContacts interaction={interaction} />
                <Box
                  sx={{
                    border: '1px solid #694FA31A',
                    borderRadius: '12px',
                    marginBottom: '24px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      padding: '16px',
                      borderBottom: '1px solid #694FA31A',
                    }}
                  >
                    <Box
                      fontSize={(theme) => theme.typography.h6}
                      color={(theme) => theme.palette.text.main}
                    >
                      Supplier contacts
                    </Box>
                  </Box>
                  <Box>
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            th: {
                              borderBottom:
                                interaction?.external_stakeholders.length === 0
                                  ? 'none'
                                  : '1px solid #694FA31A',
                              padding: '16px',
                              paddingRight: '0',
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#000000DE',
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                            >
                              Name
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                            >
                              Title
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Messages
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Last active
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {interaction?.external_stakeholders.map((item) => (
                          <TableRow
                            key={item.id}
                            sx={{
                              borderBottom: '1px solid #694FA31A',
                              td: {
                                border: '0px',
                                padding: '16px',
                                paddingRight: '0',
                                color: '#000000DE',
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                              },
                              ':last-of-type': { borderBottom: 'none' },
                            }}
                          >
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: '12px',
                                  alignItems: 'center',
                                }}
                              >
                                <Box>{item.name}</Box>
                                <Box sx={{ display: 'flex', gap: '8px' }}>
                                  <Box
                                    sx={{ position: 'relative' }}
                                    onMouseEnter={() =>
                                      handleMailPopoverOpen(item.id)
                                    }
                                    onMouseLeave={handleMailPopoverClose}
                                  >
                                    <IconButton sx={{ padding: 0 }}>
                                      <StyledIcon
                                        component={MailOutlineRounded}
                                        color={'secondary'}
                                        sx={{ fontSize: 20 }}
                                      />
                                    </IconButton>

                                    <CustomDropMenu
                                      isOpen={Boolean(
                                        openMailId === item.id && item.email,
                                      )}
                                      positionVariant={'bottom-left'}
                                      extraStyles={{
                                        borderRadius: '12px',
                                        marginTop: '20px',
                                        padding: '16px',
                                        maxWidth: '500px',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '10px',
                                        }}
                                      >
                                        <Box
                                          color={(theme) =>
                                            theme.palette.text.main
                                          }
                                          fontSize={(theme) =>
                                            theme.typography.body2
                                          }
                                          sx={{
                                            lineHeight: '143%',
                                          }}
                                        >
                                          {item.email}
                                        </Box>

                                        <Box sx={{ position: 'relative' }}>
                                          <IconButton
                                            sx={{ padding: 0 }}
                                            onClick={() => {
                                              navigator.clipboard.writeText(
                                                item.email,
                                              );
                                              handleConfirmCopy(
                                                item?.id,
                                                'email',
                                              );
                                            }}
                                          >
                                            <StyledIcon
                                              component={ContentCopyRounded}
                                              color={'icon'}
                                            />
                                          </IconButton>
                                          {confirmCopy ===
                                            `${item?.id} email` && (
                                            <ConfirmCopy
                                              message={'Email copied'}
                                              positionStyles={{
                                                marginTop: '28px',
                                                marginLeft:
                                                  'calc(-100% - 12px)',
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </Box>
                                    </CustomDropMenu>
                                  </Box>
                                  {item.phone && (
                                    <Box
                                      sx={{ position: 'relative' }}
                                      onMouseEnter={() =>
                                        handlePhonePopoverOpen(item.id)
                                      }
                                      onMouseLeave={handlePhonePopoverClose}
                                    >
                                      <IconButton sx={{ padding: 0 }}>
                                        <StyledIcon
                                          component={PhoneOutlined}
                                          color={'secondary'}
                                          sx={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                      <CustomDropMenu
                                        isOpen={Boolean(
                                          openPhoneId === item.id && item.phone,
                                        )}
                                        positionVariant={'bottom-left'}
                                        extraStyles={{
                                          borderRadius: '12px',
                                          marginTop: '20px',
                                          padding: '16px',
                                          maxWidth: '500px',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                          }}
                                        >
                                          <Box
                                            color={(theme) =>
                                              theme.palette.text.main
                                            }
                                            fontSize={(theme) =>
                                              theme.typography.body2
                                            }
                                          >
                                            {item.phone}
                                          </Box>
                                          <Box sx={{ position: 'relative' }}>
                                            <IconButton
                                              sx={{ padding: 0 }}
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  item.phone,
                                                );
                                                handleConfirmCopy(
                                                  item?.id,
                                                  'phone',
                                                );
                                              }}
                                            >
                                              <StyledIcon
                                                component={ContentCopyRounded}
                                                color={'icon'}
                                              />
                                            </IconButton>
                                            {confirmCopy ===
                                              `${item?.id} phone` && (
                                              <ConfirmCopy
                                                message={'Phone copied'}
                                                positionStyles={{
                                                  marginTop: '28px',
                                                  marginLeft:
                                                    'calc(-100% - 12px)',
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                      </CustomDropMenu>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{ maxWidth: '150px', whiteSpace: 'normal' }}
                              >
                                {item.title}
                              </Box>
                            </TableCell>
                            <TableCell>{item.num_emails_total}</TableCell>
                            <TableCell>
                              {moment(item.last_email_sent_at).format(
                                'MMM DD, YYYY',
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
                <InteractionLogs interaction={interaction} />
              </Box>
              <PopupMessage
                open={Boolean(popupMessage.message)}
                message={popupMessage}
                close={() => setPopupMessage('')}
              />
            </Box>
          </>
        )}
      </Box>
      <DealClosedModal
        handleClose={() => setIsOpenDealClosed(false)}
        open={isOpenDealClosed}
        setPopupMessage={setPopupMessage}
        updateInteraction={updateInteraction}
        interaction={interaction}
      />
      <EditDealClosedModal
        handleClose={() => setIsEditDealClosed(false)}
        open={isEditDealClosed}
        updateInteraction={updateInteraction}
        interaction={interaction}
        setPopupMessage={setPopupMessage}
      />
    </Box>
  );
};

export default InteractionDetails;
