export const stageNames = [
  { label: 'Exploration', value: 'exploration' },
  { label: 'Negotiation', value: 'negotiation' },
  { label: 'Closing', value: 'closing' },
];

export const filterEngagementStage = [
  { label: stageNames[0].label, value: stageNames[0].value },
  { label: stageNames[1].label, value: stageNames[1].value },
  { label: stageNames[2].label, value: stageNames[2].value },
];

export const defaultFiltersOverview = {
  timeframe: 30,
  engagementStage: [],
  renewals: 'false',
  departments: [],
  deal_closed: 'false',
  deprioritized: 'false',
  procurementMembers: [],
  dealValueRanges: [],
};
export const defaultFiltersInteractions = {
  timeframe: 30,
  engagementStage: [],
  renewals: 'false',
  departments: [],
  deal_closed: 'false',
  procurementMembers: [],
  dealValueRanges: [],
};
export const defaultFiltersBundles = {
  reviewStatus: [],
  published: 'unknown',
  supplierDeal: [],
  contractTypes: [],
  contractTypeManual: [],
  timeframe: 7,
  interactionPresent: 'true',
  organizationId: '',
  search_query: '',
  hasContract: '',
};

export const defaultFiltersSuppliers = {
  sort_direction: 'spend-desc',
  is_active: 'null',
  is_active_contract: 'null',
  onboarding_timeframe: 'alltime',
  active_account: 'true',
};

export const maxStageInteractions = 30;
export const stageNumRows = 5;

// Currently sourced from Crunchbase (https://support.crunchbase.com/hc/en-us/articles/360050889574-What-diversity-tag-options-are-available)
export const diversityLabels = [
  {
    label: 'American Indian / Alaska Native Founded',
    value: 'American Indian / Alaska Native Founded',
  },
  {
    label: 'American Indian / Alaska Native Led',
    value: 'American Indian / Alaska Native Led',
  },
  {
    label: 'Black / African American Founded',
    value: 'Black / African American Founded',
  },
  {
    label: 'Black / African American Led',
    value: 'Black / African American Led',
  },
  { label: 'East Asian Founded', value: 'East Asian Founded' },
  { label: 'East Asian Led', value: 'East Asian Led' },
  { label: 'Hispanic / Latine Founded', value: 'Hispanic / Latine Founded' },
  { label: 'Hispanic / Latine Led', value: 'Hispanic / Latine Led' },
  { label: 'LGBTQ+ Founded', value: 'LGBTQ+ Founded' },
  { label: 'LGBTQ+ Led', value: 'LGBTQ+ Led' },
  {
    label: 'Middle Eastern / North African Founded',
    value: 'Middle Eastern / North African Founded',
  },
  {
    label: 'Middle Eastern / North African Led',
    value: 'Middle Eastern / North African Led',
  },
  {
    label: 'Native Hawaiian / Pacific Islander Founded',
    value: 'Native Hawaiian / Pacific Islander Founded',
  },
  {
    label: 'Native Hawaiian / Pacific Islander Led',
    value: 'Native Hawaiian / Pacific Islander Led',
  },
  { label: 'South Asian Founded', value: 'South Asian Founded' },
  { label: 'South Asian Led', value: 'South Asian Led' },
  { label: 'Southeast Asian Founded', value: 'Southeast Asian Founded' },
  { label: 'Southeast Asian Led', value: 'Southeast Asian Led' },
  { label: 'Women Founded', value: 'Women Founded' },
  { label: 'Women Led', value: 'Women Led' },
];

export const contractTypes = [
  { label: 'NDA', value: 'nda' },
  { label: 'Order form', value: 'orderForm' },
  { label: 'Statement of work', value: 'statementOfWork' },
  { label: 'Sponsorship', value: 'sponsorship' },
  { label: 'Staffing agreement', value: 'staffingAgreement' },
  { label: 'Vendor agreement', value: 'vendorAgreement' },
  { label: 'Other', value: 'other' },
];
