import { Box, Link, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { generateQueryParams } from './reportHelpers';
import { encodeQuery } from '../../store/storeFunctions';
import CustomCard from '../../common/CustomCard';
import SimpleLineGraph from '../../common/SimpleLineGraph';
import SpendBarGraph from './SpendBarGraph';
import SpendPieChart from './SpendPieChart';
import moment from 'moment';
import SpendByTeamModal from './SpendByTeamModal';
import SpendByStatusModal from './SpendByStatusModal';
import { FetchWithCancel } from '../../common/FetchWithCancel';
import LoadingSkeleton from '../../common/LoadingSkeleton';

const COLORS = [
  '#FFB4A2',
  '#88DBDF',
  '#88ABDF',
  '#80E0A7',
  '#F3D03E',
  '#FF808B',
];
const PIE_CHART_MAX_ROWS = 4;
const BAR_GRAPH_MAX_ROWS = 4;

const formatCurrency = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

export const formatSpendGrowth = (data) => {
  return data.map((entry) => {
    const value = Number(entry.total_usd || 0);
    return {
      ...entry,
      value,
      label: `${moment(entry.date).format('DD MMM')} - ${formatCurrency(
        value,
      )}`,
    };
  });
};

export const formatSpendStats = (data) => {
  const formattedData = data.map((entry, index) => {
    const approval = Number(entry?.in_approval_usd || 0);
    const committed = Number(entry?.committed_usd || 0);
    const billed = Number(entry?.billed_usd || 0);
    const total = approval + committed + billed;

    return {
      label: entry.cost_center,
      approval,
      approvalFormatted: formatCurrency(approval),
      committed,
      committedFormatted: formatCurrency(committed),
      billed,
      billedFormatted: formatCurrency(billed),
      value: total,
      totalFormatted: formatCurrency(total),
    };
  });

  const fullSpend = formattedData.reduce((acc, item) => acc + item.value, 0);
  const billedSpend = formattedData.reduce((acc, item) => acc + item.billed, 0)

  let totalOtherValue = 0;
  let totalOtherBilledValue = 0;
  const formatted = formattedData
    .sort((a, b) => b.value - a.value)
    .map((entry, index) => {
      if (index > PIE_CHART_MAX_ROWS - 1) {
        totalOtherValue += entry.value;
        totalOtherBilledValue += entry.billed;
      }

      return {
        ...entry,
        color: COLORS[index % COLORS.length],
        percent: `${((entry.value / fullSpend) * 100).toFixed(0)}%`,
        billedPercent: `${((entry.billed / billedSpend) * 100).toFixed(0)}%`,
      };
    });

  return {
    formatted,
    reducedView:
      formatted.length > PIE_CHART_MAX_ROWS + 1
        ? [
            ...formatted.slice(0, PIE_CHART_MAX_ROWS),
            {
              label: 'Other',
              billed: totalOtherBilledValue,
              value: totalOtherValue,
              billedFormatted: formatCurrency(totalOtherBilledValue),
              totalFormatted: formatCurrency(totalOtherValue),
              billedPercent: `${((totalOtherBilledValue / billedSpend) * 100).toFixed(0)}%`,
              percent: `${((totalOtherValue / fullSpend) * 100).toFixed(0)}%`,
              color: COLORS.at(-2),
            },
          ]
        : formatted,
  };
};

export const calculateTotal = (data, useNumber) => {
  // find the most recent value
  const total = data.slice(-1)[0]?.value || 0;
  return useNumber ? total : formatCurrency(total);
};

export const calculateByStatus = (data) => {
  const dataTotals = data.reduce(
    (acc, item) => {
      return {
        approval: acc.approval + item.approval,
        committed: acc.committed + item.committed,
        billed: acc.billed + item.billed,
        value: acc.value + item.value,
      };
    },
    {
      approval: 0,
      committed: 0,
      billed: 0,
      value: 0,
    },
  );

  return {
    ...dataTotals,
    approvalFormatted: formatCurrency(dataTotals.approval),
    committedFormatted: formatCurrency(dataTotals.committed),
    billedFormatted: formatCurrency(dataTotals.billed),
  };
};

const SpendGraphs = ({ filters }) => {
  const theme = useTheme();
  const [isSpendLoading, setIsSpendLoading] = useState(true);
  const [isGrowthLoading, setIsGrowthLoading] = useState(true);
  const [spendGrowth, setSpendGrowth] = useState([]);
  const [spendStats, setSpendStats] = useState([]);
  const [spendTotal, setSpendTotal] = useState();
  const [totalByStatus, setTotalByStatus] = useState();
  const [otherTotalByTeam, setOtherTotalByTeam] = useState([]);
  const [isSpendByTeamOpen, setIsSpendByTeamOpen] = useState(false);
  const [isSpendByStatusOpen, setIsSpendByStatusOpen] = useState(false);

  const getStats = (reportType) => {
    const query = {
      ...generateQueryParams(filters, 'MM/DD/YYYY'),
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/${reportType}`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  useEffect(() => {
    setIsSpendLoading(true);
    setIsGrowthLoading(true);

    const { fetchPromise: spendStatsFetch, cancel: spendStatsCancel } =
      getStats('spend_stats');
    const { fetchPromise: spendGrowthFetch, cancel: spendGrowthCancel } =
      getStats('spend_growth');

    spendStatsFetch.then((data) => {
      if (data == 'error') {
        data = {};
      }
      const formattedSpendStats = formatSpendStats(data?.spend_stats || []);
      const statusTotal = calculateByStatus(formattedSpendStats?.formatted);
      setSpendStats(formattedSpendStats?.formatted);
      setTotalByStatus(statusTotal);
      setOtherTotalByTeam(formattedSpendStats?.reducedView);
      setIsSpendLoading(false);
    });

    spendGrowthFetch.then((data) => {
      if (data == 'error') {
        data = {};
      }
      const formattedSpendGrowth = formatSpendGrowth(data?.spend_growth || []);
      const total = calculateTotal(formattedSpendGrowth);
      setSpendGrowth(formattedSpendGrowth);
      setSpendTotal(total);
      setIsGrowthLoading(false);
    });

    return () => {
      spendStatsCancel && spendStatsCancel();
      spendGrowthCancel && spendGrowthCancel();
    };
  }, [filters]);

  const getColor = (variant) => {
    if (variant > 0) {
      return theme.palette.icon_success.main;
    } else if (variant < 0) {
      return theme.palette.icon_error.main;
    } else {
      return theme.palette.icon.main;
    }
  };

  if (isSpendLoading || isGrowthLoading) {
    return (
      <Box>
        <LoadingSkeleton variant={'spendGraphs'} />
      </Box>
    );
  }

  return (
    <>
      <CustomCard>
        <Box display={'flex'} padding={'16px'} gap={'16px'}>
          <Box
            width={'50%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'8px'}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.body1}
            >
              Total spend
            </Box>
            <Box display={'flex'} gap={'32px'} alignItems={'center'}>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h4}
              >
                {spendTotal}
              </Box>
              <SimpleLineGraph
                data={spendGrowth}
                getColor={getColor}
                valueKey="label"
              />
            </Box>
          </Box>
          <Box width={'50%'}>
            <SpendBarGraph spend={totalByStatus} legend />
          </Box>
        </Box>
      </CustomCard>
      <Box
        display={'flex'}
        gap={'24px'}
        paddingTop={'32px'}
        paddingBottom={'32px'}
      >
        <CustomCard>
          <Box display={'flex'} padding={'16px'} gap={'16px'}>
            <Box
              width={'50%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'10px'}
            >
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                Spend by team
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                {otherTotalByTeam.map((spend, index) => (
                  <Box
                    key={`by-team-${spend.label}-${index}`}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'8px'}
                  >
                    <Box>
                      <svg width="8" height="8">
                        <circle cx="4" cy="4" r="4" fill={spend.color} />
                      </svg>
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.black}
                      fontSize={(theme) => theme.typography.body2}
                      width={'120px'}
                    >
                      {spend.label}
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.main}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {spend.totalFormatted}
                    </Box>
                    <Box
                      color={(theme) => theme.palette.text.medium}
                      fontSize={(theme) => theme.typography.body2}
                    >
                      {`(${spend.percent})`}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box width={'50%'}>
              <SpendPieChart data={otherTotalByTeam} />
            </Box>
          </Box>
          {Boolean(spendStats.length > PIE_CHART_MAX_ROWS + 1) && (
            <Box
              sx={{
                padding: '16px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
              }}
            >
              <Link
                color="secondary"
                sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
                underline="hover"
                onClick={() => {
                  setIsSpendByTeamOpen(true);
                }}
              >
                See all teams
              </Link>
            </Box>
          )}
        </CustomCard>
        <CustomCard>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
            padding={'16px'}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h6}
              paddingBottom={'6px'}
            >
              Spend by status
            </Box>
            {spendStats.slice(0, BAR_GRAPH_MAX_ROWS).map((spend, index) => (
              <Box key={`by-status-${spend.label}-${index}`}>
                <SpendBarGraph label={spend.label} spend={spend} />
              </Box>
            ))}
          </Box>
          {Boolean(spendStats.length > BAR_GRAPH_MAX_ROWS) && (
            <Box
              sx={{
                padding: '16px 16px',
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'left',
              }}
            >
              <Link
                color="secondary"
                sx={{ fontSize: '14px', fontWeight: '500', cursor: 'pointer' }}
                underline="hover"
                onClick={() => {
                  setIsSpendByStatusOpen(true);
                }}
              >
                See all teams
              </Link>
            </Box>
          )}
        </CustomCard>
      </Box>
      <SpendByTeamModal
        open={isSpendByTeamOpen}
        handleClose={() => {
          setIsSpendByTeamOpen(false);
        }}
        collection={spendStats}
      />
      <SpendByStatusModal
        open={isSpendByStatusOpen}
        handleClose={() => {
          setIsSpendByStatusOpen(false);
        }}
        collection={spendStats}
      />
    </>
  );
};

export default SpendGraphs;
