import { Box, Stack, useTheme } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import { formatCurrency } from '../../utils/functions';
import {
  formatYAxis,
  IconDescriptionVariant,
} from '../supplierDetails/SupplierDetailsSpendGraph';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomActiveDot from '../../common/CustomActiveDot';
import DateTooltip from '../../common/DateTooltip';
import moment from 'moment';
import InfoMessageHover from '../../common/InfoMessageHover';

const DashboardSpendTimelineGraph = ({ total, data }) => {
  const theme = useTheme();
  const get_color = () => {
    return theme.palette.icon.main;
  };

  const displayedMonths = new Set();

  return (
    <CustomCard height={'320px'}>
      <Box padding={'16px'}>
        <Stack gap={'2px'} paddingBottom={'8px'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h6}
              display={'flex'}
              gap={'8px'}
              alignItems={'center'}
            >
              <Box>{formatCurrency(total)} total billed</Box>
              <InfoMessageHover
                message={'Total spend based on approved invoices in Netsuite'}
              />
            </Box>
          </Box>
          {/* <Box
            fontSize={(theme) => theme.typography.body2}
            color={(theme) => theme.palette.text.medium}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {IconDescriptionVariant(percentChange)}{' '}
            <Box>
              <Box component={'span'} color={get_color(percentChange)}>
                {percentChange !== Infinity
                  ? Math.abs(percentChange).toFixed(1)
                  : 0}
                %
              </Box>{' '}
              vs last year
            </Box>
          </Box> */}
        </Stack>
        <ResponsiveContainer width="99%" height={250}>
          <LineChart height={250}>
            <XAxis
              dataKey="date"
              tickLine={false}
              tick={({ x, y, payload }) => {
                const monthLabel = moment(payload.value).format('MMM YY');

                if (!monthLabel || displayedMonths.has(monthLabel)) return null;

                displayedMonths.add(monthLabel);
                return (
                  <text
                    x={x}
                    y={y + 10}
                    textAnchor="middle"
                    fill="rgba(0, 0, 0, 0.87)"
                  >
                    {monthLabel}
                  </text>
                );
              }}
            />
            <YAxis
              type="number"
              allowDataOverflow={true}
              domain={[0, 'auto']}
              tickFormatter={(value) => formatYAxis(value)}
            />
            <CartesianGrid vertical={false} />
            <Line
              dataKey="value"
              data={data}
              stroke={'#6558B1'}
              type="monotone"
              dot={() => null}
              activeDot={
                <CustomActiveDot
                  r={3}
                  xAxis={{ x: 0 }}
                  yAxis={{ y: 0 }}
                  colorDot={'#6558B1'}
                  height={250}
                />
              }
              isAnimationActive={false}
            />
            <Tooltip
              cursor={false}
              wrapperStyle={{ outline: 'none' }}
              allowEscapeViewBox={{ x: true, y: true }}
              content={<DateTooltip valueKey={'label'} />}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </CustomCard>
  );
};

export default DashboardSpendTimelineGraph;
