import {
  ArrowForwardRounded,
  ChatOutlined,
  Language,
  RequestQuote,
  WarningAmber,
} from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import { ReactComponent as DealValue } from '../../common/icons/request_quote.svg';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import Logo from '../../common/Logo';
import StyledIcon from '../../common/StyledIcon';
import SupplierBadges from '../../common/SupplierBadges.js';
import { formatCurrency } from '../../utils/functions.js';

const SupplierAlternativeItem = ({ supplier, sidebar }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { page } = useSelector((state) => state.appSlice);

  const detailsLink =
    page === 'overview'
      ? `/details/supplier/${supplier.id}${sidebar ? '' : '/fullscreen'}`
      : `/${page}/details/supplier/${supplier.id}${sidebar ? '' : '/fullscreen'}${search}`;

  return (
    <CustomCard isHover={true} onClick={() => navigate(detailsLink)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '10px',
          borderBottom: '1px solid #DEDBEC',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              width: 'calc(100% - 30px)',
            }}
          >
            <Logo size={54} img={supplier.image_url} />
            <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'4px'}
                alignItems={'center'}
              >
                <Box
                  color={(theme) => theme.palette.text.main}
                  sx={{
                    lineHeight: '150%',
                  }}
                  fontSize={(theme) => theme.typography.subtitle1}
                >
                  {supplier.name}
                </Box>
                <SupplierBadges
                  supplier={supplier}
                  gap={'4px'}
                  manualActiveInteraction
                  iconOnly
                />
              </Box>
              {Boolean(supplier.categories_ai) && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '10px',
                    maxHeight: '60px',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  {supplier.categories_ai.map((item, index) => (
                    <Box
                      key={`category-${index}`}
                      sx={{
                        border: '1px solid rgba(2, 136, 209, 0.5)',
                        borderRadius: '100px',
                        padding: '4px 10px',
                        fontSize: '13px',
                        color: '#0288D1',
                      }}
                    >
                      {item}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width={'30px'}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton type="button" sx={{ padding: '8px' }}>
                <StyledIcon component={ArrowForwardRounded} color={'icon'} />
              </IconButton>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box sx={{ minHeight: '50px', padding: '16px', display: 'flex' }}>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            width: 'calc(100% / 2)',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            paddingRight: '8px',
          }}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.body2}
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'flex-start',
            }}
          >
            <Box>
              <Teams />
            </Box>
            <Box
              sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}
              fontSize={(theme) => theme.typography.body1}
            >
              {supplier.teams_ai?.slice(0, 3).join(', ')}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: 'calc(100% / 2)',
          }}
        >
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <DealValue />
            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              <Box color={(theme) => theme.palette.text.main}>
                {formatCurrency(supplier.total_spend, true)}
              </Box>
            </Box>
          </Box>
          <Box fontSize={(theme) => theme.typography.body2}>
            <Box
              component={'span'}
              color={(theme) => theme.palette.text.medium}
            >
              Supplier since:{' '}
            </Box>
            <Box component={'span'} color={(theme) => theme.palette.text.main}>
              {supplier.onboarded_at}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default SupplierAlternativeItem;
