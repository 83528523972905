import moment from 'moment';

// TODO: Send prefix from backend when we get more :)
const SAMSARA_NS_PREFIX =
  'https://4146860.app.netsuite.com/app/accounting/transactions/';

export const DEFAULT_REPORT_FILTERS = {
  start_date: moment().subtract(30, 'day'),
  end_date: moment(),
  people: [],
  team: [],
  status: [],
  timeframe: 30,
};

export const EMPLOYEE_SPENDING_STATS_HEADERS = [
  {
    label: 'Team',
    width: '210px',
    key: 'department',
    align: 'left',
    id: 'department',
    numeric: false,
    sort: true,
  },
  {
    label: 'Employee',
    width: '150px',
    key: 'user_name',
    align: 'left',
    id: 'user_name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Total spend',
    width: '100px',
    key: 'total_spend_amount_usd',
    id: 'total_spend_amount',
    numeric: true,
    sort: true,
  },
  {
    label: 'Average spend',
    width: '100px',
    key: 'avg_total_spend_amount_usd',
    id: 'avg_total_spend_amount',
    numeric: true,
    sort: true,
  },
  {
    label: 'Total requests',
    width: '100px',
    key: 'total_requests',
    id: 'total_requests',
    numeric: true,
    sort: true,
    requestLink: true,
  },
  {
    label: 'Total unique vendors',
    width: '100px',
    key: 'unique_suppliers',
    id: 'unique_suppliers',
    numeric: true,
    sort: true,
    // supplierLink: true,
  },
];

export const CATEGORY_SPENDING_STATS_HEADERS = [
  {
    label: 'Category',
    width: '375px',
    key: 'category',
    align: 'left',
    id: 'category',
    numeric: false,
    sort: true,
  },
  {
    label: 'Total spend',
    width: '100px',
    key: 'total_spend_amount_usd',
    id: 'total_spend_amount',
    numeric: true,
    sort: true,
  },
  {
    label: 'Average spend',
    width: '100px',
    key: 'avg_total_spend_amount_usd',
    id: 'avg_total_spend_amount',
    numeric: true,
    sort: true,
  },
  {
    label: 'Total requests',
    width: '100px',
    key: 'total_requests',
    id: 'total_requests',
    numeric: true,
    sort: true,
    requestLink: true,
  },
  {
    label: 'Total unique vendors',
    width: '100px',
    key: 'unique_suppliers',
    id: 'unique_suppliers',
    numeric: true,
    sort: true,
    // supplierLink: true,
  },
];

export const SUPPLIER_SPENDING_STATS_HEADERS = [
  {
    label: 'Name',
    width: '200px',
    key: 'supplier_name',
    align: 'left',
    id: 'supplier_name',
    numeric: false,
    sort: true,
    // supplier: true,
  },
  {
    label: 'Total spend',
    width: '100px',
    key: 'total_spend_amount_usd',
    id: 'total_spend_amount',
    numeric: true,
    sort: true,
  },
  {
    label: 'Average spend',
    width: '100px',
    key: 'avg_total_spend_amount_usd',
    id: 'avg_total_spend_amount',
    numeric: true,
    sort: true,
  },
  {
    label: 'Total requests',
    width: '100px',
    key: 'total_requests',
    id: 'total_requests',
    numeric: true,
    sort: true,
    requestLink: true,
  },
];

export const SUPPLIER_DRILL_DOWN_HEADERS = [
  {
    label: 'Supplier',
    width: '230px',
    key: 'supplier_name',
    align: 'left',
    id: 'supplier_name',
    numeric: false,
    sort: true,
    supplier: true,
  },
  {
    label: 'Total amount',
    width: '100px',
    key: 'total_spend_amount_usd',
    id: 'total_spend_amount',
    numeric: true,
    sort: true,
  },
];

export const REQUEST_DRILL_DOWN_HEADERS = [
  {
    label: 'Employee',
    width: '100px',
    key: 'user_name',
    align: 'left',
    id: 'user_name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Request',
    width: '270px',
    key: 'request_label',
    align: 'left',
    id: 'request_label',
    numeric: false,
    sort: true,
    requestTicket: true,
  },
  {
    label: 'Supplier',
    width: '100px',
    key: 'supplier_name',
    align: 'left',
    id: 'supplier_name',
    numeric: false,
    sort: true,
  },
  {
    label: 'In approval',
    width: '100px',
    key: 'in_approval_total_usd',
    id: 'in_approval_total',
    numeric: true,
    sort: true,
  },
  {
    label: 'Committed',
    width: '100px',
    key: 'committed_total_usd',
    id: 'committed_total',
    numeric: true,
    sort: true,
  },
  {
    label: 'Billed',
    width: '100px',
    key: 'billed_total_usd',
    id: 'billed_total',
    numeric: true,
    sort: true,
  },
];

export const PEOPLE_PROCUREMENT_ENGAGEMENT_HEADERS = [
  {
    label: 'Category',
    width: '200px',
    key: 'category_label',
    align: 'left',
    id: 'category_label',
    numeric: false,
    sort: true,
  },

  {
    label: 'Total requests',
    width: '50px',
    key: 'count',
    id: 'count',
    numeric: true,
    sort: true,
  },
  {
    label: 'Total amount',
    width: '50px',
    key: 'price_dollars_usd',
    id: 'price_dollars',
    numeric: true,
    sort: true,
  },
  {
    label: 'Days without strategic sourcing',
    width: '100px',
    key: 'independent_engagement_days',
    id: 'independent_engagement_days',
    numeric: true,
    sort: true,
  },
  {
    label: 'Days with strategic sourcing',
    width: '100px',
    key: 'collaborative_engagement_days',
    id: 'collaborative_engagement_days',
    numeric: true,
    sort: true,
  },
  {
    label: 'Days in workflow',
    width: '95px',
    key: 'workflow_days',
    id: 'workflow_days',
    numeric: true,
    sort: true,
  },
];

export const REQUEST_PROCUREMENT_ENGAGEMENT_HEADERS = [
  {
    label: 'Request ID',
    width: '50px',
    key: 'request_number',
    id: 'request_number',
    numeric: false,
    sort: true,
  },
  {
    label: 'Supplier name',
    width: '200px',
    key: 'vendor_name',
    id: 'vendor_name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Amount',
    width: '50px',
    key: 'price_dollars_usd',
    id: 'price_dollars',
    numeric: true,
    sort: true,
  },
  {
    label: 'Days without strategic sourcing',
    width: '100px',
    key: 'independent_engagement_days',
    id: 'independent_engagement_days',
    numeric: true,
    sort: true,
  },
  {
    label: 'Days with strategic sourcing',
    width: '100px',
    key: 'collaborative_engagement_days',
    id: 'collaborative_engagement_days',
    numeric: true,
    sort: true,
  },
  {
    label: 'Days in workflow',
    width: '95px',
    key: 'workflow_days',
    id: 'workflow_days',
    numeric: true,
    sort: true,
  },
];

export const CROSS_TEAM_VENDORS_HEADERS = [
  {
    label: 'Supplier domain',
    width: '140px',
    key: 'domain',
    align: 'left',
  },
  {
    label: 'Partner Element supplier',
    width: '120px',
    key: 'linkUrl',
    link: true,
    align: 'left',
  },
  {
    label: 'Supplier names',
    width: '150px',
    key: 'vendor_name_unique',
    align: 'left',
  },
  {
    label: 'Teams',
    width: '200px',
    key: 'department_name_unique',
    align: 'left',
  },
  {
    label: 'Median days in workflow',
    width: '50px',
    key: 'total_duration_mean',
  },
  {
    label: 'Total requests',
    width: '50px',
    key: 'request_number_nunique',
  },
  { label: 'Average amount', width: '100px', key: 'price_in_dollars_mean_usd' },
  { label: 'Total amount', width: '100px', key: 'price_in_dollars_sum_usd' },
];

export const DUPLICATE_VENDORS_HEADERS = [
  {
    label: 'Supplier domain',
    width: '140px',
    key: 'domain',
    align: 'left',
  },
  {
    label: 'Partner Element supplier',
    width: '125px',
    key: 'linkUrl',
    link: true,
    align: 'left',
  },
  {
    label: 'Supplier name',
    width: '150px',
    key: 'vendor_name',
    align: 'left',
  },
  { label: 'Employee', width: '200px', key: 'user_name', align: 'left' },
  {
    label: 'Median days in workflow',
    width: '50px',
    key: 'median_workflow_days',
  },
  {
    label: 'Total requests',
    width: '50px',
    key: 'request_nunique',
  },
  { label: 'Average amount', width: '100px', key: 'price_usd_mean_usd' },
  { label: 'Total amount', width: '100px', key: 'price_usd_total_usd' },
];

export function generateFilters(filter_options) {
  const team = filter_options.cost_centers
    .map((department) => {
      return { label: department, value: department };
    })
    .filter((item) => item.label !== null)
    .sort((a, b) => a.label.localeCompare(b.label));

  const people = filter_options.team
    .map((stakeholder) => {
      return {
        label: stakeholder.name,
        value: stakeholder.email,
        team: stakeholder.department,
      };
    })
    .filter((item) => item.label !== null)
    .sort((a, b) => a.label.localeCompare(b.label));
  return { people, team };
}

export function generateQueryParams(filters, dateFormat, hideStatus) {
  if (!filters) return {};
  return {
    cost_centers: filters.team,
    team: filters.people,
    start_date: moment(filters.start_date).format(dateFormat || 'X'),
    end_date: moment(filters.end_date).format(dateFormat || 'X'),
    status: hideStatus ? null : filters.status,
  };
}

export function calculateSpendTabColumns(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['total_spend_amount', 'avg_total_spend_amount'],
    createLink: {
      label: 'supplier_name',
      id: 'supplier_id',
      match: 'image_url',
      trim: 2,
    },
  })
    .sort((a, b) => Number(b.total_spend_amount) - Number(a.total_spend_amount))
    .filter((item) => item.total_requests > 0);
}

export function calculateSupplierDrillDownColumns(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['total_spend_amount'],
    createLink: {
      label: 'supplier_name',
      id: 'supplier_id',
      match: 'image_url',
      trim: 2,
    },
  }).filter((item) => Number(item?.total_spend_amount) > 0);
}

export function calculateRequestDrillDownColumns(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['in_approval_total', 'committed_total', 'billed_total'],
  }).filter((item) => Number(item?.total_spend_amount) > 0);
}

export function calculateCrossTeamSuppliersColumns(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['price_in_dollars_sum', 'price_in_dollars_mean'],
    createLink: {
      label: 'name',
      id: 'id',
      match: 'is_supplier',
      trim: 2,
    },
  }).sort((a, b) => a.name.localeCompare(b.name));
}

export function calculatedDuplicateSuppliersColumns(collection) {
  const seenDomains = [];
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['price_usd_mean', 'price_usd_total'],
    createLink: {
      label: 'supplier_name',
      id: 'supplier_uuid',
      match: 'supplier_uuid',
      trim: 0,
    },
  })
    .sort(
      (a, b) =>
        a.domain.localeCompare(b.domain) ||
        b.price_usd_total - a.price_usd_total,
    )
    .map((item) => {
      const domain = item['domain'];
      if (seenDomains.includes(domain)) {
        return { ...item, linkUrl: '', linkLabel: '', domain: '' };
      }
      seenDomains.push(domain);
      return item;
    });
}

export function calculateDepartmentTimelineColumns(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    findTotal: true,
    sumColumns: [
      'independent_duration_mean',
      'collaboration_duration_mean',
      'total_duration_mean',
    ],
  })
    .filter((item) => item.user_department_name !== null)
    .sort((a, b) =>
      a.user_department_name.localeCompare(b.user_department_name),
    );
}

export function calculatePeopleTimeline(collection) {
  return calculateColumns(collection, {
    type: 'user_name',
    meanColumns: [
      'independent_engagement_days',
      'collaborative_engagement_days',
      'workflow_days',
    ],
    sumColumns: ['price_dollars'],
    moneyColumns: ['price_dollars'],
  }).sort((a, b) => Number(b.price_dollars) - Number(a.price_dollars));
}

export function calculatePeopleDetailsColumns(collection) {
  return calculateColumns(collection, {
    type: 'category_label',
    meanColumns: [
      'independent_engagement_days',
      'collaborative_engagement_days',
      'workflow_days',
    ],
    sumColumns: ['price_dollars'],
    moneyColumns: ['price_dollars'],
  }).sort((a, b) => Number(b.price_dollars) - Number(a.price_dollars));
}

export function calculateRequestDetailsColumns(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['price_dollars'],
  }).sort((a, b) => Number(b.price_dollars) - Number(a.price_dollars));
}

export function calculateSupplierSpendPurchaseOrder(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['amount_usd_total', 'amount_usd_unbilled'],
    createLink: {
      label: 'document_number',
      id: 'external_id',
      linkPrefix: `${SAMSARA_NS_PREFIX}purchord.nl?id=`,
    },
  });
}

export function calculateSupplierSpendInvoice(collection) {
  return calculateColumns(collection, {
    noFilter: true,
    moneyColumns: ['amount_usd_total', 'amount_usd_unbilled'],
    createLink: {
      label: 'invoice_document_number',
      id: 'external_id',
      linkPrefix: `${SAMSARA_NS_PREFIX}invoice.nl?id=`,
    },
  });
}

function calculateColumns(collection, options) {
  const reduceType = options.type;
  const columnsToSum = options.sumColumns || [];
  const columnsToMean = options.meanColumns || [];
  const moneyColumns = options.moneyColumns || [];
  const aggregateData = collection.reduce((acc, curr) => {
    const existing = options.noFilter
      ? false
      : acc.find((item) => item[reduceType] === curr[reduceType]);

    if (!existing) {
      const updated = { ...curr };
      columnsToSum.forEach((key) => {
        updated[key] = Number(curr[key]);
      });

      columnsToMean.forEach((key) => {
        updated[key] = Number(curr[key]);
      });

      if (options.findTotal) {
        updated.total = 0;
        columnsToSum.forEach((key) => {
          updated.total += Number(curr[key]);
        });
      }

      acc.push({ count: 1, ...updated });
      return acc;
    }

    columnsToSum.forEach((key) => {
      existing[key] += Number(curr[key]);
    });

    columnsToMean.forEach((key) => {
      existing[key] += Number(curr[key]);
    });

    existing.count += 1;

    return acc;
  }, []);

  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const highestTotal = Math.max(...aggregateData.map((o) => o.total));

  return aggregateData.map((item) => {
    const updatedItem = item;
    if (options.createLink) {
      updatedItem.linkLabel = updatedItem[options.createLink.label];
    }
    if (
      options.createLink &&
      updatedItem[options.createLink.id] &&
      updatedItem[options.createLink.match]
    ) {
      updatedItem.linkUrl = `details/supplier/${updatedItem[
        options.createLink.id
      ].slice(options.createLink.trim)}/fullscreen`;
    }

    if (options.createLink && options.createLink.linkPrefix) {
      updatedItem.linkUrl = `${options.createLink.linkPrefix}${
        updatedItem[options.createLink.id]
      }`;
      updatedItem.externalLink = true;
    }

    if (options.findTotal) updatedItem.total = highestTotal;

    columnsToSum.forEach((key) => {
      updatedItem[key] = Math.round(item[key]);
    });

    columnsToMean.forEach((key) => {
      updatedItem[key] = Math.round(item[key] / item.count);
    });

    moneyColumns.forEach((key) => {
      updatedItem[`${key}_usd`] = usdFormatter.format(item[key]);
    });
    return updatedItem;
  });
}
