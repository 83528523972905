import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { appSlice } from '../store/appSlice';
import { BoldText, ResultsBox } from './Blocks';
import ClearFiltersButton from './ClearFilters';
import InfoMessageHover from './InfoMessageHover';

const typeWords = {
  interactions: {
    single: 'interaction',
    plural: 'interactions',
    variant: 'filtersInteractions',
  },
  suppliers: {
    single: 'supplier',
    plural: 'suppliers',
    variant: 'filtersSuppliers',
  },
};

const StyledClearButton = styled(Button)({
  textTransform: 'none',
});

const ListSummary = ({ type, count }) => {
  const dispatch = useDispatch();
  const { filtersSuppliers } = useSelector((state) => state.appSlice);
  const [searchParams, setSearchParams] = useSearchParams();

  const isSearchResult = searchParams.get('q');
  const resultCountText = `${count} ${
    count === 1 ? typeWords[type].single : typeWords[type].plural
  }${type === 'suppliers' ? ' from your systems' : ''}${
    isSearchResult ? '' : '.'
  }`;
  const subHeaderSearchText = `There ${count === 1 ? 'is' : 'are'} ${count}
    ${
      count === 1 ? typeWords[type].single : typeWords[type].plural
    } matching “${searchParams.get('q')}”.`;

  const clearSearch = () => {
    setSearchParams((params) => {
      params.delete('q');
      return params;
    });
    if (type === 'suppliers')
      dispatch(
        appSlice.actions.setFiltersSuppliers({
          ...filtersSuppliers,
          sort_direction: 'asc',
        }),
      );
  };
  return (
    <Box>
      <ResultsBox sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <Box display={'flex'} alignItems={'center'}>
          <BoldText isBold={isSearchResult}>{resultCountText}</BoldText>
        </Box>
        <InfoMessageHover
          positionVariant={'bottom-right'}
          message={`Partner Element merges supplier accounts across systems based on matching domains, tax IDs, or other company identifiers. This may result in different supplier counts than shown in your other systems.`}
        />
        <ClearFiltersButton
          variant={typeWords[type].variant}
        ></ClearFiltersButton>
      </ResultsBox>

      {isSearchResult && (
        <Box>
          <span
            color={(theme) => theme.palette.text.caption}
            fontSize={(theme) => theme.typography.body1}
          >
            {subHeaderSearchText}
          </span>
          <StyledClearButton onClick={clearSearch} color="secondary">
            Clear search
          </StyledClearButton>
        </Box>
      )}
    </Box>
  );
};

export default ListSummary;
