import React, { useState } from 'react';
import { Box, FormControl, Link, Select, Typography } from '@mui/material';
import Logo from '../../common/Logo';

function ZipWorkflowLink({ requestTickets }) {
  const [open, setOpen] = useState(false);
  if (requestTickets.length === 1)
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '250px',
        }}
      >
        <Logo size={24} img={'/ZipHQ-icon.png'} />
        <Link
          underline="none"
          fontSize={(theme) => theme.typography.body2}
          sx={{
            cursor: 'pointer',
          }}
          href={requestTickets[0].link}
          target="_blank"
          rel="noopener noreferrer"
          color="secondary"
        >
          {requestTickets[0].label}
        </Link>
      </Box>
    );
  const CustomLabel = () => (
    <Typography>{requestTickets.length} requests</Typography>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
      }}
    >
      <Logo size={26} img={'/ZipHQ-icon.png'} />
      <FormControl size="small">
        <Select
          displayEmpty
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          sx={{
            borderRadius: '100px',
            backgroundColor: open ? '#6558B114' : 'white',
            border: '1px solid #6558B11A',
          }}
          renderValue={() => <CustomLabel />}
        >
          {requestTickets.map((item, index) => (
            <Link
              key={index}
              underline="none"
              fontSize={(theme) => theme.typography.body2}
              sx={{
                cursor: 'pointer',
                display: 'block',
                width: '100%',
                py: 1,
                px: 2,
              }}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              color="secondary"
            >
              {item.label}
            </Link>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ZipWorkflowLink;
