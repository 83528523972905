import { createAsyncThunk } from '@reduxjs/toolkit';
import { isProcurementUser } from '../utils/functions';

const funcGetInteractions = async (queryParams, { rejectWithValue }) => {
  const { isBackoffice, ...params } = queryParams;
  const baseUrl = isBackoffice
    ? process.env.REACT_APP_BACKOFFICE_BASE_URL
    : process.env.REACT_APP_API_BASE_URL;
  const apiUrl = `${baseUrl}/interactions`;
  const queryString = encodeQuery(params);
  const urlWithQuery = `${apiUrl}?${queryString}`;

  try {
    const response = await fetch(urlWithQuery, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    });

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
};

const funcGetBundles = async (queryParams, { rejectWithValue }) => {
  const apiUrl = `${process.env.REACT_APP_BACKOFFICE_BASE_URL}/bundles`;
  const queryString = encodeQuery(queryParams);
  const urlWithQuery = `${apiUrl}?${queryString}`;

  try {
    const response = await fetch(urlWithQuery, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    });

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
};

export const getInteractions = createAsyncThunk(
  'app/getInteractions',
  funcGetInteractions,
);

export const getBundles = createAsyncThunk('app/getBundles', funcGetBundles);

export const getInteractionsOverview = createAsyncThunk(
  'app/getInteractionsOverview',
  funcGetInteractions,
);

export const getInteractionsBackoffice = createAsyncThunk(
  'app/getInteractionsBackoffice',
  funcGetInteractions,
);

export const getNewInteractions = createAsyncThunk(
  'app/getNewInteractions',
  funcGetInteractions,
);

export const getDepartments = createAsyncThunk(
  'app/getDepartments',
  async (_, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/departments`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  },
);

export const getProcurementFilterData = createAsyncThunk(
  'app/getProcurementFilterData',
  async (_, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/users/filter_options`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  },
);

export const getDealValueRanges = createAsyncThunk(
  'app/getDealValueRanges',
  async (_, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/organizations/deal_value_breakpoints`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  },
);

export const getNotifications = createAsyncThunk(
  'app/getNotifications',
  async (_, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/announcements`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  },
);
export const deleteNotification = createAsyncThunk(
  'app/deleteNotification',
  async (id, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/announcements/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
        credentials: 'include',
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue('Error');
    }
  },
);

export const getUser = createAsyncThunk(
  'app/getUser',
  async (auth0_org_id, { rejectWithValue }) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/current_user`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    let result = await response.json();
    if (response.ok) {
      return result;
    } else {
      // Append the Org ID to the authorization redirect for Omniauth on backend
      result.auth0_org_id = auth0_org_id;
      return rejectWithValue(result);
    }
  },
);

export const getSuppliers = createAsyncThunk(
  'app/getSuppliers',
  async (queryParams, { rejectWithValue }) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/suppliers`;
    const queryString = encodeQuery(queryParams);
    const urlWithQuery = `${apiUrl}?${queryString}&paginate=true`;

    try {
      const response = await fetch(urlWithQuery, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue('Error');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export function encodeQuery(params) {
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(
            (item) =>
              `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`,
          )
          .join('&');
      } else {
        return Boolean(value !== 'null' && value)
          ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : '';
      }
    })
    .filter(Boolean)
    .join('&');
}

export function generateEmployeeFilters(user, employees, employeeFilter) {
  if (!window.location.hostname.includes('app.partnerelement'))
    return employeeFilter;
  if (isProcurementUser(user) || employeeFilter?.length)
    return employeeFilter;
  return employees.map((employee) => employee.value);
}
