import { Box, IconButton, Modal, Stack } from '@mui/material';
import StyledIcon from '../../common/StyledIcon';
import { CloseRounded } from '@mui/icons-material';
import InteractionItem from '../../common/InteractionItem';
import ActivityItem from '../../common/ActivityItem';

const DashboardMyActivityModal = ({ open, handleClose, activities }) => {
  const combinedActivities = [
    ...(activities?.current_supplier || []),
    ...(activities?.new_supplier || []),
  ].sort(
    (a, b) =>
      new Date(b.end_time || b.started_at) -
      new Date(a.end_time || a.started_at),
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 'fit-content',
          minWidth: '830px',
          maxWidth: '1080px',
          maxHeight: '640px',
          background: 'white',
          position: 'absolute',
          marginTop: '35px',
          top: '0',
          left: 'calc(50% - 415px)',
          borderRadius: '12px',
          padding: '24px',
          overflow: 'auto',
        }}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            color={(theme) => theme.palette.text.primary}
            fontSize={(theme) => theme.typography.h6}
          >
            My activity
          </Box>
          <IconButton padding={'8px'} onClick={handleClose}>
            <StyledIcon component={CloseRounded} color={'icon'} />
          </IconButton>
        </Stack>
        <Stack gap={'16px'}>
          <Box
            color={(theme) => theme.palette.text.secondary}
            fontSize={(theme) => theme.typography.body1}
          >
            {`${combinedActivities.length || 0} activities total`}
          </Box>
          <>
            {combinedActivities.map((activity) => {
              if (activity.record_type === 'interaction')
                return (
                  <InteractionItem
                    variant={'Dashboard'}
                    interaction={activity}
                  />
                );
              return <ActivityItem activity={activity} />;
            })}
          </>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DashboardMyActivityModal;
