import { FullStory } from '@fullstory/browser';
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import { FetchWithCancel } from '../../common/FetchWithCancel';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import { appSlice } from '../../store/appSlice';
import {
  getFiltersDashboard,
  updateFiltersReports,
} from '../../store/localStorage';
import { encodeQuery, getDealValueRanges } from '../../store/storeFunctions';
import { defaultFiltersSuppliers } from '../../utils/constants';
import { formatCurrency } from '../../utils/functions';
import {
  calculateSpendTabColumns,
  DEFAULT_REPORT_FILTERS,
  generateFilters,
  generateQueryParams,
} from '../reports/reportHelpers';
import {
  calculateByStatus,
  calculateTotal,
  formatSpendGrowth,
  formatSpendStats,
} from '../reports/SpendGraphs';
import { DashboardDateFilters, DashboardTeamFilters } from './DashboardFilters';
import DashboardMyActivity from './DashboardMyActivity';
import DashboardOpenInteractions from './DashboardOpenInteractions';
import DashboardPieChart from './DashboardPieChart';
import DashboardProcurementStats from './DashboardProcurementStats';
import DashboardSpendTimelineGraph from './DashboardSpendTimelineGraph';
import DashboardStatusChart from './DashboardStatusChart';
import DashboardSuppliers from './DashboardSuppliers';

export const DEFAULT_DASHBOARD_FILTERS = {
  timeframe: 12,
  interactionTimeframe: 30,
  start_date: moment().subtract(12, 'month'),
  end_date: moment(),
  team: [],
  status: 'billed',
};

const calculateTotalProcurement = (data) => {
  const statLength = data.length;
  if (!statLength) return {};
  const procurementTotals = data.reduce(
    (acc, curr) => {
      return {
        before: acc.before + Number(curr.independent_duration_mean),
        workflow: acc.workflow + Number(curr.total_duration_mean),
      };
    },
    { before: 0, workflow: 0 },
  );
  procurementTotals.before = Math.round(procurementTotals.before / statLength);
  procurementTotals.workflow = Math.round(
    procurementTotals.workflow / statLength,
  );
  return procurementTotals;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPage } = appSlice.actions;

  const [filters, setFilters] = useState(getFiltersDashboard());
  const [teamMatches, setTeamMatches] = useState([]);
  const [filterValues, setFilterValues] = useState();
  const [isSpendLoading, setIsSpendLoading] = useState(true);
  const [isGrowthLoading, setIsGrowthLoading] = useState(true);
  const [isCategoryLoading, setIsCategoryLoading] = useState(true);
  const [isProcurementLoading, setIsProcurementLoading] = useState(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);
  const [isInteractionsLoading, setIsInteractionsLoading] = useState(true);
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
  const [isSupplierStatsLoading, setIsSupplierStatsLoading] = useState(true);
  const [spendGrowth, setSpendGrowth] = useState([]);
  const [spendTotal, setSpendTotal] = useState();
  const [totalByStatus, setTotalByStatus] = useState();
  const [otherTotalByTeam, setOtherTotalByTeam] = useState([]);
  const [categoryCollection, setCategoryCollection] = useState([]);
  const [procurementStats, setProcurementStats] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [activities, setActivities] = useState();
  const [supplierStats, setSupplierStats] = useState();

  const handleLinkClick = (type) => {
    dispatch(setPage(type));
    navigate(`/${type}`);
    window.scrollTo(0, 0);
  };

  const handleSupplierClick = (showAll) => {
    const timeframeInDays =
      filters.timeframe === 'custom'
        ? 'custom'
        : filters.timeframe === 12
        ? 365
        : filters.timeframe * 30;
    dispatch(
      appSlice.actions.setFiltersSuppliers({
        ...defaultFiltersSuppliers,
        department_ids: filters.team,
        start_date: showAll
          ? null
          : moment(filters.start_date).format('MM/DD/YYYY'),
        end_date: showAll
          ? null
          : moment(filters.end_date).format('MM/DD/YYYY'),
        onboarding_timeframe: showAll ? 'alltime' : timeframeInDays,
        active_account: 'true',
      }),
    );
    handleLinkClick('suppliers');
  };

  const getStats = (spendType, status) => {
    const query = {
      ...generateQueryParams(filters, 'MM/DD/YYYY', status),
    };
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/${spendType}`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  const getTimeline = (subjectType) => {
    const query = {
      subject: subjectType,
      ...generateQueryParams(filters),
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/report/timeline`;
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;
    return FetchWithCancel(urlWithQuery);
  };

  const getData = async (url) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${url}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const fetchInteractions = async () => {
    const teamMatches = filters.team.map((item) => `/${item}`);
    setTeamMatches(teamMatches);
    setIsInteractionsLoading(true);

    const apiUrl = '/interactions';
    const queryString = encodeQuery({
      department_ids: teamMatches,
      timeframe: filters.interactionTimeframe,
      sort_by: 'urgency',
      sort_direction: 'desc',
    });
    const urlWithQuery = `${apiUrl}?${queryString}`;
    const data = await getData(urlWithQuery);
    if (data !== 'error') {
      setInteractions(data);
    }
    setIsInteractionsLoading(false);
  };

  const fetchActivities = async () => {
    setIsActivitiesLoading(true);
    const data = await getData(
      `/activities?start_date=${moment()
        .subtract(1, 'month')
        .format('MM/DD/YYYY')}&end_date=${moment().format('MM/DD/YYYY')}`,
    );
    if (data !== 'error') {
      setActivities(data);
    }
    setIsActivitiesLoading(false);
  };

  const fetchSupplierStats = async () => {
    const query = {
      ...generateQueryParams(filters, 'MM/DD/YYYY'),
    };

    const apiUrl = '/stats/dashboard_summary_report';
    const queryString = encodeQuery(query);
    const urlWithQuery = `${apiUrl}?${queryString}`;

    setIsSupplierStatsLoading(true);
    const data = await getData(urlWithQuery);
    if (data !== 'error') {
      setSupplierStats(data);
    }
    setIsSupplierStatsLoading(false);
  };

  const fetchTeamFilterData = async () => {
    setIsFiltersLoading(true);
    const data = await getData('/report/filters');
    if (data !== 'error') {
      const filterData = generateFilters(data);
      setFilterValues(filterData);
    }
    setIsFiltersLoading(false);
  };

  useEffect(() => {
    fetchTeamFilterData();
    fetchActivities();
    dispatch(getDealValueRanges());
  }, []);

  useEffect(() => {
    fetchInteractions();
  }, [filters.team, filters.interactionTimeframe]);

  // report graph data
  useEffect(() => {
    if (!filters) return;
    setIsSpendLoading(true);
    setIsGrowthLoading(true);
    setIsCategoryLoading(true);
    setIsProcurementLoading(true);

    const { fetchPromise: spendStatsFetch, cancel: spendStatsCancel } =
      getStats('spend_stats', true);
    const { fetchPromise: spendGrowthFetch, cancel: spendGrowthCancel } =
      getStats('spend_growth');
    const { fetchPromise: spendCategoryFetch, cancel: spendCategoryCancel } =
      getStats('spend_category');
    const {
      fetchPromise: departmentTimelineFetch,
      cancel: departmentTimelineCancel,
    } = getTimeline('department');

    spendStatsFetch.then((data) => {
      if (data == 'error') {
        data = {};
      }
      const formattedSpendStats = formatSpendStats(data?.spend_stats || []);
      const statusTotal = calculateByStatus(formattedSpendStats?.formatted);
      setTotalByStatus(statusTotal);
      setOtherTotalByTeam(formattedSpendStats?.reducedView);
      setIsSpendLoading(false);
    });

    spendGrowthFetch.then((data) => {
      if (data == 'error') {
        data = {};
      }
      const formattedSpendGrowth = formatSpendGrowth(data?.spend_growth || []);
      const total = calculateTotal(formattedSpendGrowth, true);
      setSpendGrowth(formattedSpendGrowth);
      setSpendTotal(total);
      setIsGrowthLoading(false);
    });

    spendCategoryFetch.then((data) => {
      if (data !== 'error') {
        const categoryData = calculateSpendTabColumns(
          data?.spend_pivot_category || [],
        );
        const fullSpend = categoryData.reduce(
          (acc, item) => acc + Number(item.total_spend_amount),
          0,
        );
        let totalOtherValue = 0;
        const formatted = categoryData.map((entry, index) => {
          if (index > 4 - 1) {
            totalOtherValue += Number(entry.total_spend_amount);
          }

          return {
            ...entry,
            label: entry.category,
            total_spend_amount: Number(entry.total_spend_amount),
            percent: `${(
              (Number(entry.total_spend_amount) / fullSpend) *
              100
            ).toFixed(0)}%`,
          };
        });

        const formattedCategories =
          formatted.length > 5
            ? [
                ...formatted.slice(0, 4),
                {
                  label: 'Other',
                  total_spend_amount: totalOtherValue,
                  total_spend_amount_usd: formatCurrency(totalOtherValue),
                  percent: `${((totalOtherValue / fullSpend) * 100).toFixed(
                    0,
                  )}%`,
                },
              ]
            : formatted;
        setCategoryCollection(formattedCategories);
      } else {
        setCategoryCollection([]);
      }
      setIsCategoryLoading(false);
    });

    departmentTimelineFetch.then((data) => {
      if (data !== 'error') {
        const collection = calculateTotalProcurement(data?.report || []);
        setProcurementStats(collection);
      } else {
        setProcurementStats([]);
      }
      setIsProcurementLoading(false);
    });

    fetchSupplierStats();

    return () => {
      spendStatsCancel && spendStatsCancel();
      spendGrowthCancel && spendGrowthCancel();
      spendCategoryCancel && spendCategoryCancel();
      departmentTimelineCancel && departmentTimelineCancel();
    };
  }, [filters.team, filters.timeframe, filters.start_date, filters.end_date]);

  FullStory('setProperties', {
    type: 'page',
    properties: {
      pageName: 'Dashboard',
    },
  });

  if (isFiltersLoading)
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'24px'}
        padding={'24px 120px'}
      >
        <LoadingSkeleton variant={'spendGraphs'} />
      </Box>
    );

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'24px'}
      padding={'24px 120px'}
    >
      <DashboardTeamFilters
        filters={filters}
        filterValues={filterValues}
        setFilters={setFilters}
      />
      {isInteractionsLoading ? (
        <LoadingSkeleton variant={'spendGraphs'} />
      ) : (
        <DashboardOpenInteractions
          interactions={interactions}
          teams={teamMatches}
          filters={filters}
          setFilters={setFilters}
          handleClick={() => {
            handleLinkClick('interactions');
          }}
        />
      )}
      <CustomCard>
        <Box
          padding={'16px'}
          display={'grid'}
          gridTemplateColumns={'1fr 1fr'}
          gap={'16px'}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            gridColumn={'1 / -1'}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h5}
            >
              Spend insights
            </Box>
            <DashboardDateFilters filters={filters} setFilters={setFilters} />
          </Box>
          {Boolean(
            isGrowthLoading ||
              isSpendLoading ||
              isCategoryLoading ||
              isProcurementLoading,
          ) && (
            <Box gridColumn={'1 / -1'}>
              <LoadingSkeleton variant={'stats'} />
            </Box>
          )}
          {Boolean(
            !isGrowthLoading &&
              !isSpendLoading &&
              !isCategoryLoading &&
              !isProcurementLoading,
          ) && (
            <>
              <DashboardSpendTimelineGraph
                data={spendGrowth}
                total={spendTotal}
              />
              <DashboardSuppliers
                supplierStats={supplierStats}
                filters={filters}
                handleClick={(_, showAll) => {
                  handleSupplierClick(showAll);
                }}
              />
              <DashboardPieChart
                data={otherTotalByTeam}
                type={'team'}
                handleClick={() => {
                  handleSupplierClick();
                }}
              />
              <DashboardPieChart
                data={categoryCollection.slice(0, 5)}
                type={'category'}
              />
              <DashboardStatusChart
                data={totalByStatus}
                handleClick={() => {
                  updateFiltersReports({
                    ...DEFAULT_REPORT_FILTERS,
                    start_date: filters.start_date,
                    end_date: filters.end_date,
                    team: filters.team,
                    timeframe: 'custom',
                  });
                  handleLinkClick('reports');
                }}
              />
              {/* <DashboardProcurementStats
                data={procurementStats}
                handleClick={() => {
                  handleLinkClick('reports');
                }}
              /> */}
            </>
          )}
        </Box>
      </CustomCard>
      {!Boolean(isActivitiesLoading) && (
        <DashboardMyActivity
          activities={activities}
          handleClick={() => {
            handleLinkClick('interactions');
          }}
        />
      )}
    </Box>
  );
};

export default Dashboard;
