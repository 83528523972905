import { Box, Link } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import SpendBarGraph from '../reports/SpendBarGraph';

const DashboardStatusChart = ({ data, handleClick }) => {
  return (
    <CustomCard height={'320px'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'100%'}
        width={'100%'}
      >
        <Box padding={'16px'} width={'100%'}>
          <Box
            paddingRight={'32px'}
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
          >
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.h6}
            >
              Spend by status
            </Box>
            <SpendBarGraph spend={data} legend dashboard />
          </Box>
        </Box>
        <Box padding={'16px'}>
          <Link
            color="secondary"
            sx={{ cursor: 'pointer' }}
            underline="hover"
            onClick={handleClick}
            fontSize={'14px'}
            fontWeight={'500'}
          >
            See reports
          </Link>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default DashboardStatusChart;
