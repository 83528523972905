import { Box, Link, Stack, Typography } from '@mui/material';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';

const sectionStyles = {
  border: '1px solid #DEDBEC',
  borderRadius: '12px',
  padding: '16px',
};

const linkMouseover = { cursor: 'pointer' };

const SupplierDetailsTeams = ({ teams, handleClick }) => {
  return (
    <Box sx={sectionStyles} gap={'24px'}>
      <Box sx={{ paddingBottom: '16px' }}>
        <Typography variant="h6">Teams</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={'26px'}>
        <Stack flexDirection={'row'} gap={'4px'}>
          <Teams />
          <Box fontSize={(theme) => theme.typography.body2}>
            {teams.join(', ')}
          </Box>
        </Stack>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'2px'}
        >
          <Link
            sx={linkMouseover}
            color="secondary"
            underline="hover"
            fontSize={(theme) => theme.typography.subtitle2}
            onClick={handleClick}
          >
            See all people
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierDetailsTeams;
