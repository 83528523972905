import { Box, Popover } from '@mui/material';
import { DatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const datePickerStyles = {
  textField: {
    sx: {
      borderRadius: '8px',
      borderWidth: '1px',
      borderColor: '#DEDBEC',
      '.MuiInputBase-input.MuiOutlinedInput-input': {
        padding: '8px 0px 8px 12px',
        marginRight: '-8px',
      },
      '.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          borderColor: '#6558B1 !important',
        },
      '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
        color: '#6558B1',
      },
      '.MuiButtonBase-root.MuiIconButton-root': {
        color: '#6558B173',
      },
    },
  },
  desktopPaper: {
    sx: {
      boxShadow: 'none',
    },
  },
  layout: {
    sx: {
      '.MuiPickersDay-root.Mui-selected': {
        color: '#fff',
        borderRadius: '100%',
        backgroundColor: '#6558B1 !important',
        transition: 'none',
      },
      '.MuiPickersYear-yearButton.Mui-selected': {
        color: '#fff',
        backgroundColor: '#6558B1 !important',
        transition: 'none',
      },
      '.MuiSvgIcon-root': {
        color: '#6558B173',
      },
      '.MuiDateCalendar-root': {
        width: 'auto',
        maxHeight: '296px',
      },
    },
  },
};

const staticDatePickerStyles = {
  ...datePickerStyles,
  layout: {
    sx: {
      ...datePickerStyles.layout.sx,
      minWidth: '0px',
      maxHeight: '260px',
      padding: '0',
      margin: '0',
      '.MuiPickersToolbar-root': {
        display: 'none',
      },
      '.MuiDialogActions-root': {
        display: 'none',
      },
      '.MuiDateCalendar-root': {
        padding: '0',
        margin: '0',
        minWidth: '0px',
        width: 'auto',
      },
      '.MuiPickersCalendarHeader-root': {
        marginTop: '0',
      },
    },
  },
};

const CustomDateRangePickerModal = ({
  open,
  filters,
  onChange,
  onClose,
  anchorEl,
}) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box
        padding={'16px 8px'}
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
      >
        <Box display={'flex'} gap={'65px'} paddingLeft={'16px'}>
          <DatePicker
            label="From"
            open={false}
            slotProps={{
              ...datePickerStyles,
              ...{ popper: { placement: 'bottom-end' } },
            }}
            format="MM/DD/YY"
            disableFuture
            elevation={0}
            id={'start_date'}
            value={moment(filters.start_date)}
            maxDate={moment(filters.end_date).subtract(1, 'days')}
            onChange={(newValue) =>
              onChange({ ...filters, start_date: newValue })
            }
          />
          <DatePicker
            label="To"
            open={false}
            slotProps={datePickerStyles}
            format="MM/DD/YY"
            disableFuture
            id={'end_date'}
            value={moment(filters.end_date)}
            minDate={moment(filters.start_date).add(1, 'days')}
            onChange={(newValue) =>
              onChange({ ...filters, end_date: newValue })
            }
          />
        </Box>
        <Box display={'flex'} gap={'8px'}>
          <StaticDatePicker
            label="From"
            open={false}
            slotProps={staticDatePickerStyles}
            format="MM/DD/YY"
            disableFuture
            id={'start_date'}
            value={moment(filters.start_date)}
            maxDate={moment(filters.end_date).subtract(1, 'days')}
            onChange={(newValue) =>
              onChange({ ...filters, start_date: newValue })
            }
          />
          <StaticDatePicker
            label="To"
            open={false}
            slotProps={staticDatePickerStyles}
            format="MM/DD/YY"
            disableFuture
            id={'end_date'}
            value={moment(filters.end_date)}
            minDate={moment(filters.start_date).add(1, 'days')}
            onChange={(newValue) =>
              onChange({ ...filters, end_date: newValue })
            }
          />
        </Box>
      </Box>
    </Popover>
  );
};

export default CustomDateRangePickerModal;
