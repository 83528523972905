import { Box, Link, Typography } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import StyledIcon from '../../common/StyledIcon';
import { ArrowForwardRounded } from '@mui/icons-material';

const SupplierActivitySummary = ({ interactions, requests, handleClick }) => {
  if (
    !Boolean(interactions?.filter((item) => !item.deal_closed).length) &&
    !Boolean(requests?.filter((item) => item.status === 'pending').length)
  )
    return <></>;
  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant="h6">Activities</Typography>
        <Link
          sx={{ cursor: 'pointer' }}
          color="secondary"
          fontSize={(theme) => theme.typography.subtitle2}
          underline="hover"
          onClick={handleClick}
        >
          See all activities
        </Link>
      </Box>
      <Box display={'flex'} gap={'16px'}>
        <CustomCard onClick={handleClick} isHover={true}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            padding={'16px'}
          >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.h4}
            >
              {interactions?.filter((item) => !item.deal_closed).length || 0}
            </Box>
            <Box
              display={'flex'}
              alignContent={'center'}
              justifyContent={'space-between'}
            >
              <Box
                color={(theme) => theme.palette.text.primary}
                fontSize={(theme) => theme.typography.body1}
              >
                Open interactions
              </Box>
              <StyledIcon component={ArrowForwardRounded} color={'icon'} />
            </Box>
          </Box>
        </CustomCard>
        <CustomCard onClick={handleClick} isHover={true}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            padding={'16px'}
          >
            <Box
              color={(theme) => theme.palette.text.primary}
              fontSize={(theme) => theme.typography.h4}
            >
              {requests?.filter((item) => item.status === 'pending').length ||
                0}
            </Box>
            <Box
              display={'flex'}
              alignContent={'center'}
              justifyContent={'space-between'}
            >
              <Box
                color={(theme) => theme.palette.text.primary}
                fontSize={(theme) => theme.typography.body1}
              >
                Purchase requests in progress
              </Box>
              <StyledIcon component={ArrowForwardRounded} color={'icon'} />
            </Box>
          </Box>
        </CustomCard>
      </Box>
    </Box>
  );
};

export default SupplierActivitySummary;
