import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "@mui/material/styles";
import { Box, Button, IconButton, Menu, Stack } from "@mui/material";
import {
  ArrowForwardRounded,
  CloseRounded,
  TrendingDownRounded,
  TrendingFlatRounded,
  TrendingUpRounded,
} from "@mui/icons-material";

import SupplierModal from "./SupplierModal";
import ProcurementModal from "./ProcurementModal";

import CustomCard from "../../common/CustomCard";
import StyledIcon from "../../common/StyledIcon";
import Logo from "../../common/Logo";
import LoadingSkeleton from "../../common/LoadingSkeleton";

import {
  calculateInteractionsForStats,
  capitalizeFirst,
} from "../../utils/functions";
import DateTooltip from "../../common/DateTooltip";
import CustomActiveDot from "../../common/CustomActiveDot";

const IconDescriptionVariant = (variant) => {
  if (variant > 0) {
    return <StyledIcon component={TrendingUpRounded} color={"icon_success"} />;
  } else if (variant < 0) {
    return <StyledIcon component={TrendingDownRounded} color={"icon_error"} />;
  } else {
    return <StyledIcon component={TrendingFlatRounded} color={"icon"} />;
  }
};

const Stats = ({variant}) => {
  const theme = useTheme();

  const { filtersOverview, filtersInteractions } = useSelector((state) => state.appSlice);

  const [interactionsEl, setInteractionsEl] = useState(null);
  const [procurementInvolvedEl, setProcurementInvolvedEl] = useState(null);
  const [isInteractionsOpen, setIsInteractionsOpen] = useState(false);
  const [isProcurementOpen, setIsProcurementOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState();

  const getStats = async () => {
    const timeframe = variant === 'interactions' ? filtersInteractions.timeframe : filtersOverview.timeframe;
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/stats?timeframe=${timeframe}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      return "error";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getStats().then((res) => {
      res !== "error" && setStats(res);
      setIsLoading(false);
    });
  }, [filtersOverview.timeframe, filtersInteractions.timeframe]);

  const get_color = (variant) => {
    if (variant > 0) {
      return theme.palette.icon_success.main;
    } else if (variant < 0) {
      return theme.palette.icon_error.main;
    } else {
      return theme.palette.icon.main;
    }
  };

  const emptyStats = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "24px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{ minWidth: "250px", width: "calc(50% - 12px)", height: "168px" }}
        >
          <CustomCard></CustomCard>
        </Box>
        <Box
          sx={{ minWidth: "250px", width: "calc(50% - 12px)", height: "168px" }}
        >
          <CustomCard></CustomCard>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "24px",
          flexWrap: "wrap",
        }}
      >
        {!isLoading ? (
          !stats ? (
            emptyStats()
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "24px",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ minWidth: "250px", width: "calc(50% - 12px)" }}>
                <CustomCard>
                  <Box
                    sx={{
                      padding: "16px",
                      borderBottom: "1px solid #79747E29",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          marginBottom: "8px",
                        }}
                        color={(theme) => theme.palette.text.main}
                        fontSize={(theme) => theme.typography.body1}
                      >
                        Active interactions
                      </Box>
                      <Box
                        fontSize={(theme) => theme.typography.h4}
                        color={(theme) => theme.palette.text.main}
                      >
                        {stats?.interaction_summary?.total_interactions}{" "}
                      </Box>
                    </Box>
                    <Box width={"50%"}>
                      <ResponsiveContainer width={"100%"} height={50}>
                        <LineChart height={50}>
                          <XAxis hide={true} />
                          <YAxis hide={true} />
                          <Line
                            type="linear"
                            dataKey="value"
                            data={stats?.interaction_summary?.chart.map(
                              (value, index) => ({
                                value,
                                index,
                              })
                            )}
                            stroke={get_color(
                              stats?.interaction_summary?.trend_percent
                            )}
                            dot={({ cx, cy, index }) =>
                              index ===
                              stats?.interaction_summary?.chart?.length - 1 ? (
                                <circle
                                  cx={cx}
                                  cy={cy}
                                  r={3}
                                  key={index}
                                  fill={get_color(
                                    stats?.interaction_summary?.trend_percent
                                  )}
                                />
                              ) : null
                            }
                            activeDot={
                              <CustomActiveDot
                                r={3}
                                xAxis={{ x: 0 }}
                                yAxis={{ y: 0 }}
                                colorDot={get_color(
                                  stats?.interaction_summary?.trend_percent
                                )}
                                height={50}
                              />
                            }
                            isAnimationActive={false}
                          />

                          <Tooltip
                            cursor={false}
                            wrapperStyle={{ outline: "none" }}
                            allowEscapeViewBox={{ x: true, y: true }}
                            content={<DateTooltip dates={stats?.interaction_summary?.chart_dates} />}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                  <Box>
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        fontSize={(theme) => theme.typography.body2}
                        color={(theme) => theme.palette.text.medium}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          padding: "16px",
                        }}
                      >
                        {IconDescriptionVariant(
                          stats?.interaction_summary?.trend_percent
                        )}{" "}
                        <Box>
                          <Box
                            component={"span"}
                            color={get_color(
                              stats?.interaction_summary?.trend_percent
                            )}
                          >
                            {Math.abs(
                              stats?.interaction_summary?.trend_percent
                            ).toFixed(1)}
                            %
                          </Box>{" "}
                          vs {stats?.interaction_summary?.trend_period}
                        </Box>
                      </Box>
                      <Button
                        onClick={() => setIsInteractionsOpen(true)}
                        color={"secondary"}
                        sx={{ textTransform: "none", padding: "16px" }}
                      >
                        Supplier insights
                      </Button>
                    </Stack>
                    <Menu
                      open={Boolean(interactionsEl)}
                      onClose={() => setInteractionsEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        sx: {
                          ul: { padding: "0" },
                          borderRadius: "12px",
                          width: "325px",
                          maxWidth: "350px",
                          fontFamily: "Roboto",
                          marginTop: "6px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          padding: "16px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: "180px",
                          }}
                          color={(theme) => theme.palette.text.black}
                          fontSize={(theme) => theme.typography.subtitle1}
                        >
                          Top active suppliers
                        </Box>
                        <IconButton
                          sx={{ padding: 0, height: "fit-content" }}
                          onClick={() => setInteractionsEl(null)}
                        >
                          <StyledIcon component={CloseRounded} color={"icon"} />
                        </IconButton>
                      </Box>
                      <Box sx={{ maxHeight: "320px" }}>
                        <Stack width={"100%"}>
                          {stats?.interaction_summary?.insights
                            ?.slice(0, 5)
                            .map((item, index) => (
                              <Stack
                                key={index}
                                width={"calc(100% - 32px)"}
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                sx={{
                                  borderTop:
                                    "1.5px solid rgba(105, 79, 163, 0.2)",
                                  padding: "10px 16px",
                                }}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={"16px"}
                                >
                                  <Logo
                                    size={28}
                                    img={item.supplier_image_url}
                                    iconSize={20}
                                  />
                                  <Box
                                    color={(theme) => theme.palette.text.main}
                                    fontSize={(theme) => theme.typography.body2}
                                    sx={{ whiteSpace: "nowrap" }}
                                  >
                                    {item?.supplier_name}
                                  </Box>
                                </Stack>
                                <Box>
                                  <Box
                                    color={(theme) => theme.palette.text.main}
                                    fontSize={(theme) => theme.typography.body2}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    {item?.num_emails} messages
                                  </Box>
                                  <Box
                                    color={(theme) => theme.palette.text.medium}
                                    fontSize={(theme) =>
                                      theme.typography.caption
                                    }
                                    sx={{ whiteSpace: "nowrap" }}
                                  >
                                    {calculateInteractionsForStats(item)}{" "}
                                    interaction(s)
                                  </Box>
                                </Box>
                              </Stack>
                            ))}
                        </Stack>
                      </Box>
                      <Button
                        onClick={() => {
                          setIsInteractionsOpen(true);
                          setInteractionsEl(null);
                        }}
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          display: "block",
                          borderRadius: "0px",
                          borderTop: "1.5px solid rgba(105, 79, 163, 0.2)",
                          padding: "16px 22px",
                        }}
                        color={"secondary"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box
                            fontSize={(theme) => theme.typography.subtitle2}
                            sx={{
                              color: "#694FA3",
                            }}
                          >
                            View all
                          </Box>
                          <StyledIcon
                            component={ArrowForwardRounded}
                            color={"icon"}
                          />
                        </Stack>
                      </Button>
                    </Menu>
                  </Box>
                </CustomCard>
              </Box>
              <Box sx={{ minWidth: "250px", width: "calc(50% - 12px)" }}>
                <CustomCard>
                  <Box
                    sx={{
                      padding: "16px",
                      borderBottom: "1px solid #79747E29",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box width={"50%"}>
                      <Box
                        sx={{
                          marginBottom: "8px",
                        }}
                        color={(theme) => theme.palette.text.main}
                        fontSize={(theme) => theme.typography.body1}
                      >
                        Procurement engaged
                      </Box>
                      <Box
                        fontSize={(theme) => theme.typography.h4}
                        color={(theme) => theme.palette.text.main}
                      >
                        {Math.round(
                          stats?.procurement_involvement_summary?.total_percent
                        )}
                        %
                      </Box>
                    </Box>
                    <Box width={"50%"}>
                      <ResponsiveContainer width="100%" height={50}>
                        <LineChart height={50}>
                          <XAxis hide={true} />
                          <YAxis hide={true} />
                          <Line
                            activeDot={
                              <CustomActiveDot
                                r={3}
                                xAxis={{ x: 0 }}
                                yAxis={{ y: 0 }}
                                colorDot={get_color(
                                  stats?.procurement_involvement_summary
                                    ?.trend_percent
                                )}
                                height={50}
                              />
                            }
                            type="linear"
                            dataKey="value"
                            data={stats?.procurement_involvement_summary?.chart?.map(
                              (value, index) => ({
                                value,
                                index,
                              })
                            )}
                            stroke={get_color(
                              stats?.procurement_involvement_summary
                                ?.trend_percent
                            )}
                            dot={({ cx, cy, index }) =>
                              index ===
                              stats?.procurement_involvement_summary?.chart
                                ?.length -
                                1 ? (
                                <circle
                                  cx={cx}
                                  cy={cy}
                                  r={3}
                                  key={index}
                                  fill={get_color(
                                    stats?.procurement_involvement_summary
                                      ?.trend_percent
                                  )}
                                />
                              ) : null
                            }
                            isAnimationActive={false}
                          />
                          <Tooltip
                            cursor={false}
                            wrapperStyle={{ outline: "none" }}
                            allowEscapeViewBox={{ x: true, y: true }}
                            content={<DateTooltip dates={stats?.procurement_involvement_summary?.chart_dates} />}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        padding: "16px",
                      }}
                      fontSize={(theme) => theme.typography.body2}
                      color={(theme) => theme.palette.text.medium}
                    >
                      {IconDescriptionVariant(
                        stats?.procurement_involvement_summary?.trend_percent
                      )}{" "}
                      <Box>
                        <Box
                          component={"span"}
                          color={get_color(
                            stats?.procurement_involvement_summary
                              ?.trend_percent
                          )}
                        >
                          {Math.abs(
                            stats?.procurement_involvement_summary
                              ?.trend_percent
                          ).toFixed(1)}
                          %
                        </Box>{" "}
                        vs {stats?.interaction_summary?.trend_period}
                      </Box>
                    </Box>
                    <Button
                      onClick={() => setIsProcurementOpen(true)}
                      color={"secondary"}
                      sx={{ textTransform: "none", padding: "16px" }}
                    >
                      Team insights
                    </Button>
                    <Menu
                      open={Boolean(procurementInvolvedEl)}
                      onClose={() => setProcurementInvolvedEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        sx: {
                          borderRadius: "12px",
                          width: "325px",
                          maxWidth: "350px",
                          padding: "16px",
                          fontFamily: "Roboto",
                          marginTop: "6px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "16px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "300px",
                          }}
                          color={(theme) => theme.palette.text.black}
                          fontSize={(theme) => theme.typography.subtitle1}
                        >
                          Procurement engaged by teams
                        </Box>
                        <IconButton
                          sx={{ padding: 0, height: "fit-content" }}
                          onClick={() => setProcurementInvolvedEl(null)}
                        >
                          <StyledIcon component={CloseRounded} color={"icon"} />
                        </IconButton>
                      </Box>
                      <Box sx={{ maxHeight: "265px", overflowY: "auto" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          {stats?.procurement_involvement_summary?.insights?.map(
                            (item, index) => (
                              <Box key={index}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box
                                    fontSize={(theme) => theme.typography.body2}
                                    color={(theme) => theme.palette.text.medium}
                                    sx={{
                                      marginBottom: "8px",
                                    }}
                                  >
                                    {capitalizeFirst(item?.department_name)}
                                  </Box>
                                  <Box
                                    fontSize={(theme) => theme.typography.body2}
                                    color={(theme) => theme.palette.text.black}
                                  >
                                    {Math.round(item?.total_percent)}%
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    background: "#5056A914",
                                    height: "10px",
                                    borderRadius: "38px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      background: "#5056A9",
                                      height: "10px",
                                      width: `${item?.total_percent}%`,
                                      borderRadius: "38px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            )
                          )}
                        </Box>
                      </Box>
                      <Button
                        onClick={() => {
                          setIsProcurementOpen(true);
                          setProcurementInvolvedEl(null);
                        }}
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          display: "block",
                        }}
                        color={"secondary"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box
                            fontSize={(theme) => theme.typography.subtitle2}
                            sx={{
                              color: "#694FA3",
                            }}
                          >
                            View all
                          </Box>
                          <StyledIcon
                            component={ArrowForwardRounded}
                            color={"icon"}
                          />
                        </Stack>
                      </Button>
                    </Menu>
                  </Stack>
                </CustomCard>
              </Box>
            </Box>
          )
        ) : (
          <LoadingSkeleton variant={"stats"} />
        )}
      </Box>

      <SupplierModal
        open={isInteractionsOpen}
        handleClose={() => setIsInteractionsOpen(false)}
        total={stats?.interaction_summary?.insights.length}
        items={stats?.interaction_summary?.insights || []}
      />
      <ProcurementModal
        open={isProcurementOpen}
        handleClose={() => setIsProcurementOpen(false)}
        total={stats?.procurement_involvement_summary?.insights.length}
        items={stats?.procurement_involvement_summary?.insights || []}
      />
    </>
  );
};

export default Stats;
