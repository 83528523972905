import { useEffect, useState } from 'react';
import NoResultCard from '../../common/NoResultCard';
import { Box } from '@mui/material';
import CustomChip from '../../common/CustomChip';
import SupplierDetailsSpendGraph from './SupplierDetailsSpendGraph';
import SupplierDetailsSpendPieChart from './SupplierDetailsSpendPieChart';
import ReportTable from '../reports/ReportTable';
import {
  calculateSupplierSpendInvoice,
  calculateSupplierSpendPurchaseOrder,
} from '../reports/reportHelpers';
import SupplierDetailsStructure from './SupplierDetailsStructure';
import moment from 'moment';

export const firstTimeframeWithData = (data) => {
  if (!data || !data.length) return 12;
  const invoices = [...data].sort(
    (a, b) => moment(b.issue_date).valueOf() - moment(a.issue_date).valueOf(),
  );
  const mostRecent = invoices[0];

  const issueDate = moment(mostRecent.issue_date);
  const currentDate = moment();

  const totalMonthsAgo = currentDate.diff(issueDate, 'months');
  if (totalMonthsAgo <= 12) {
    return 12;
  } else if (totalMonthsAgo <= 60) {
    return 60;
  } else {
    return 'max';
  }
};

const timeframeFilters = [
  { label: '6M', value: 6 },
  { label: '1Y', value: 12 },
  { label: '5Y', value: 60 },
  { label: 'Max', value: 'max' },
];

const poTableOptions = {
  type: 'purchase order',
  includeBorder: true,
  includeHeader: true,
  plural: 'purchase orders',
  modal: true,
};

const invoiceTableOptions = {
  type: 'invoice',
  includeBorder: true,
  includeHeader: true,
  plural: 'invoices',
  modal: true,
};

const poHeaders = [
  {
    label: 'PO #',
    width: '140px',
    align: 'left',
    id: 'document_number',
    numeric: false,
    sort: true,
    key: 'linkUrl',
    link: true,
  },
  {
    label: 'Account name',
    width: '125px',
    key: 'name',
    align: 'left',
    id: 'name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Status',
    width: '125px',
    key: 'status',
    align: 'left',
    id: 'status',
    numeric: false,
    sort: true,
  },
  {
    label: 'Date',
    width: '125px',
    key: 'issue_date',
    align: 'left',
    id: 'issue_date',
    numeric: false,
    sort: true,
  },
  {
    label: 'Requestor',
    width: '150px',
    key: 'requestor_name',
    align: 'left',
    id: 'requestor_name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Total in USD',
    width: '100px',
    key: 'amount_usd_total_usd',
    align: 'left',
    id: 'amount_usd_total',
    numeric: true,
    sort: true,
  },
  {
    label: 'Unbilled in USD',
    width: '100px',
    key: 'amount_usd_unbilled_usd',
    align: 'left',
    id: 'amount_usd_unbilled',
    numeric: true,
    sort: true,
  },
  {
    label: 'Item summary',
    width: '100px',
    key: 'spend_account',
    align: 'left',
    id: 'spend_account',
    numeric: false,
    sort: true,
  },
];

const invoiceHeaders = [
  {
    label: 'Invoice #',
    width: '140px',
    align: 'left',
    id: 'invoice_document_number',
    numeric: false,
    sort: true,
    key: 'linkUrl',
    link: true,
  },
  {
    label: 'Account name',
    width: '125px',
    key: 'name',
    align: 'left',
    id: 'name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Status',
    width: '125px',
    key: 'status',
    align: 'left',
    id: 'status',
    numeric: false,
    sort: true,
  },
  {
    label: 'Date',
    width: '125px',
    key: 'issue_date',
    align: 'left',
    id: 'issue_date',
    numeric: false,
    sort: true,
  },
  {
    label: 'Requestor',
    width: '150px',
    key: 'requestor_name',
    align: 'left',
    id: 'requestor_name',
    numeric: false,
    sort: true,
  },
  {
    label: 'Total in USD',
    width: '200px',
    key: 'amount_usd_total_usd',
    align: 'left',
    id: 'amount_usd_total',
    numeric: true,
    sort: true,
  },
  {
    label: 'PO #',
    width: '50px',
    key: 'po_document_number',
    align: 'left',
    id: 'po_document_number',
    numeric: false,
    sort: true,
  },
];

const poSidebarHeaders = [
  {
    label: 'PO #',
    width: '140px',
    align: 'left',
    id: 'document_number',
    numeric: false,
    sort: true,
    key: 'linkUrl',
    link: true,
  },
  {
    label: 'Date',
    width: '125px',
    key: 'issue_date',
    align: 'left',
    id: 'issue_date',
    numeric: false,
    sort: true,
  },
  {
    label: 'Total in USD',
    width: '200px',
    key: 'amount_usd_total_usd',
    align: 'left',
    id: 'amount_usd_total',
    numeric: true,
    sort: true,
  },
];

const invoiceSidebarHeaders = [
  {
    label: 'Invoice #',
    width: '140px',
    align: 'left',
    id: 'invoice_document_number',
    numeric: false,
    sort: true,
    key: 'linkUrl',
    link: true,
  },
  {
    label: 'Date',
    width: '125px',
    key: 'issue_date',
    align: 'left',
    id: 'issue_date',
    numeric: false,
    sort: true,
  },
  {
    label: 'Total in USD',
    width: '200px',
    key: 'amount_usd_total_usd',
    align: 'left',
    id: 'amount_usd_total',
    numeric: true,
    sort: true,
  },
];

const SupplierDetailsSpend = ({
  invoices,
  purchase_orders,
  sidebar,
  supplierName,
}) => {
  const [timeframe, setTimeframe] = useState();
  const [poCollection, setPoCollection] = useState([]);
  const [invoiceCollection, setInvoiceCollection] = useState([]);

  useEffect(() => {
    const po = calculateSupplierSpendPurchaseOrder(purchase_orders || []);
    const invoice = calculateSupplierSpendInvoice(invoices || []);

    const timeframe = firstTimeframeWithData(invoices);

    setTimeframe(timeframe);
    setPoCollection(po);
    setInvoiceCollection(invoice);
  }, [invoices, purchase_orders]);

  if (!invoices.length && !purchase_orders.length) {
    return <NoResultCard variant="SupplierSpend" />;
  }

  return (
    <Box maxWidth={sidebar ? '632px' : '1200px'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={sidebar ? '16px' : '24px'}
      >
        <Box>
          <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
            {timeframeFilters.map((item) => (
              <CustomChip
                key={item.label}
                label={item.label}
                isActive={timeframe === item.value}
                onClick={() => {
                  setTimeframe(item.value);
                }}
              />
            ))}
          </Box>
        </Box>
        {Boolean(invoices.length) && (
          <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
            <Box
              display={'grid'}
              gap={'24px'}
              gridTemplateColumns={
                sidebar
                  ? 'repeat(auto-fit, minmax(632px, 1fr))'
                  : 'repeat(auto-fit, minmax(500px, 1fr))'
              }
            >
              <Box
                width={sidebar ? '632px' : '100%'}
                order={sidebar ? '2' : '1'}
              >
                <SupplierDetailsStructure
                  invoices={invoices}
                  timeframe={timeframe}
                  sidebar={sidebar}
                  supplierName={supplierName}
                />
              </Box>
              <Box
                width={sidebar ? '632px' : '100%'}
                order={sidebar ? '1' : '2'}
              >
                <SupplierDetailsSpendGraph
                  data={invoices}
                  timeframe={timeframe}
                />
              </Box>
            </Box>
            <Box
              display={'grid'}
              gap={'24px'}
              gridTemplateColumns={
                sidebar
                  ? 'repeat(auto-fit, minmax(632px, 1fr))'
                  : 'repeat(auto-fit, minmax(500px, 1fr))'
              }
            >
              <SupplierDetailsSpendPieChart
                invoices={invoices}
                timeframe={timeframe}
                sidebar={sidebar}
              />
            </Box>
          </Box>
        )}

        {Boolean(purchase_orders.length) && (
          <ReportTable
            tableHeaders={sidebar ? poSidebarHeaders : poHeaders}
            collection={poCollection}
            options={poTableOptions}
          />
        )}
        {Boolean(invoices.length) && (
          <ReportTable
            tableHeaders={sidebar ? invoiceSidebarHeaders : invoiceHeaders}
            collection={invoiceCollection}
            options={invoiceTableOptions}
          />
        )}
      </Box>
    </Box>
  );
};

export default SupplierDetailsSpend;
