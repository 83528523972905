export const getSupplierAlternativeDemoData = (supplierId) => {
  const copySupplierId = COPY_SUPPLIERS_MAP[supplierId];
  return (
    supplierAlternativeDemoData[supplierId] ||
    supplierAlternativeDemoData[copySupplierId] ||
    []
  );
};

const COPY_SUPPLIERS_MAP = {
  'd6755705-92c4-430e-b794-d05be71a696f':
    '718ab69c-d0c0-4ab0-ae67-8658d25bfb9e', // Airtable
  'cc73e03b-10c2-4491-8173-35502db388cd':
    'ee44e0b7-d7e1-4f82-8ee1-5debd71c98ef', // MVF
  'ce5eb4d2-61c9-4ef8-94da-3b9e8b66ecad':
    '4b13b3da-1abe-4e0b-82b6-5b881120beb5', // Gartner
  '50bed6ea-0db3-470d-b819-431817e519b6':
    '8f83e297-5cd5-4185-b391-1ed0cff0b0a3', // Insight Global
  '8500a4e0-d837-4dac-a9fc-543113077b03':
    '24f59eee-3e7a-4312-94da-c057a9c1e224', // Glasshouse Productions
  'ce217dc2-660a-4eee-b950-64197814e494':
    'c79f0b33-84e6-4298-bac8-d431c4fd9afe', // PricewaterhouseCoopers
  '04813b93-1c57-480c-8d6c-4642f63d89c3':
    '6998aa8a-5a4e-45a5-a8ed-0cfb90c8374d', // ZoomInfo
  '0e92a715-1238-4622-b573-e2a671261bc3':
    '47d65e8e-c38b-426b-bb5a-fccbdd4a990c', //Enso
};

const supplierAlternativeDemoData = {
  '718ab69c-d0c0-4ab0-ae67-8658d25bfb9e': [
    // Airtable
    {
      id: 'd9063ef4-0893-4fb1-98e4-fb163a91a3fc',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/rzw9vcowqwe7wh6bbcir',
      active_account: true,
      onboarded_at: 'May 3, 2019',
      active_contract: true,
      contract_expiring: true,
      manual_active_interaction: false,
      name: 'Smartsheet',
      total_spend: 190486.47,
      country: ['United States'],
      categories_ai: [
        'Project Management Software',
        'Software Licenses',
        'Software Add-ons',
      ],
      teams_ai: [
        '641 Information Technology',
        '5050 Operations Exec Admin',
        '651 Workplace',
        'DNU R&D Operations',
      ],
    },
    {
      id: '4281fe0d-2cb4-4806-b414-522aeee36526',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/hv5zj5kfofe6qq2yjh32',
      active_account: true,
      onboarded_at: 'March 20, 2019',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Asana',
      total_spend: 0.0,
      country: ['United States'],
      categories_ai: ['Project Management Software'],
      teams_ai: ['641 Information Technology'],
    },
  ],
  'ee44e0b7-d7e1-4f82-8ee1-5debd71c98ef': [
    // MVF
    {
      id: 'a5db991d-826b-4197-aff3-be1d749c8e4a',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1502180317/xuxyrys1ck0niwvenft0.png',
      active_account: false,
      onboarded_at: 'Apr 4, 2019',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'L W Commercial Vehicles LLP (dba Fleet Marketing Group)',
      total_spend: 0.0,
      country: [],
      categories_ai: ['Lead Generation', 'Vehicle Tracking'],
      teams_ai: ['DNU Growth'],
    },
    {
      id: 'f3508e1d-2003-4d0c-8875-02399b3596d0',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/p4cvgjfk7mtnd1hbhjkj',
      active_account: true,
      onboarded_at: 'Oct 11, 2023',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'TFLI',
      total_spend: 15035.22,
      country: ['United States'],
      categories_ai: [
        'Lead Generation',
        'Price Comparison',
        'Vehicle Tracking',
      ],
      teams_ai: ['370 EMEA Marketing'],
    },
    {
      id: 'ae05b192-6079-4c9a-9eee-c7d195acc392',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/bshewzyp82jhftu0a8ox',
      active_account: true,
      onboarded_at: 'Dec 2, 2019',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Dataforce',
      total_spend: 3127.9,
      country: ['Germany'],
      categories_ai: ['Lead generation', 'Marketing data'],
      teams_ai: [
        'DNU Marketing Operations',
        '3010 Corporate Marketing',
        'DNU Growth',
        '370 EMEA Marketing',
      ],
    },
    {
      id: '2263f4a5-2437-44f3-aebd-8773fa5d83cc',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/t0hnh93ibuwwhn3sqe26',
      active_account: true,
      onboarded_at: 'June 13, 2018',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'IHS Markit',
      total_spend: 0.0,
      country: ['United States'],
      categories_ai: [
        'Lead Generation',
        'Market Data',
        'Professional Services',
      ],
      teams_ai: ['DNU Growth', '380 Marketing Ops', '3012 Growth'],
    },
    {
      id: '3ad24db3-a157-4b2c-9923-f29d0014bcc7',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/ars86shupsrfddowpgac',
      active_account: false,
      onboarded_at: 'Aug 27, 2018',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Engage',
      total_spend: 0.0,
      country: [],
      teams_ai: ['DNU Growth', '222 Sales Transformation'],
      categories_ai: [
        'Lead Generation',
        'Marketing Services',
        'Email Marketing',
      ],
    },
    {
      id: '859f4417-2b24-42e6-aaed-67c2f2f3ea83',
      image_url: 'https://logo.clearbit.com/companeo.com',
      active_account: true,
      onboarded_at: 'Dec 16, 2022',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Companeo Groupe',
      total_spend: 3846.7,
      country: ['France'],
      categories_ai: ['Lead Generation'],
      teams_ai: ['370 EMEA Marketing'],
    },
  ],
  '4b13b3da-1abe-4e0b-82b6-5b881120beb5': [
    // Gartner
    {
      id: '20b92797-c5b9-4863-bc7a-cccb7f27547d',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1502180317/xuxyrys1ck0niwvenft0.png',
      active_account: true,
      onboarded_at: 'Dec 14, 2021',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Forrester',
      total_spend: 70356.0,
      country: ['United States'],
      categories_ai: ['Market Research Reports', 'Marketing Materials'],
      teams_ai: [
        '330 Product Marketing',
        '3012 Growth',
        'DNU Growth',
        '330 Product and Partner Marketing',
        '212 ADR Manager',
      ],
    },
    {
      id: 'f05852cf-a050-489f-bfd8-7a6815fd42c9',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1428380045/wr85dheitgvmbeiyswbx.png',
      active_account: true,
      onboarded_at: 'Mar 27, 2019',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Frost & Sullivan',
      total_spend: 25000.0,
      country: ['United States'],
      categories_ai: [
        'Industry Research',
        'Analyst Services',
        'Report Licensing',
      ],
      teams_ai: [
        '360 Communications',
        '330 Product Marketing',
        '340 Content and AR',
        'DNU Product Marketing',
      ],
    },
    {
      id: 'a7d0b7e2-9ec2-4f93-b7ae-2e111b4d0173',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/whtfpdceb471oszu1e2z',
      active_account: true,
      onboarded_at: 'May 24, 2022',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'IDG List Services',
      total_spend: 150700.0,
      country: ['United States'],
      categories_ai: ['Market Research', 'Analyst Services', 'Event Services'],
      teams_ai: [
        'DNU Product Marketing',
        '360 Communications',
        '340 Content and AR',
        '320 Events',
        '311 CMO Strategic Initiatives',
        '650 Legal',
        '674 Investor Relations & Corporate Development',
        '213 Sales Exec',
      ],
    },
    {
      id: 'efbe796c-a2a0-4813-bd6d-0394dbc404f8',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/bfxcem5albetdchh7wzp',
      active_account: true,
      onboarded_at: 'Feb 26, 2020',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Wakefield Research',
      total_spend: 197725.0,
      country: ['United States'],
      categories_ai: ['Market Research', 'Surveys', 'Custom Research'],
      teams_ai: [
        '340 Content and AR',
        '330 Product Marketing',
        '360 Communications',
        '361 Brand',
      ],
    },
  ],
  '8f83e297-5cd5-4185-b391-1ed0cff0b0a3': [
    // Insight Global
    {
      id: 'b06405d6-b74b-4543-8129-fe2610c10d1b',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/cdibfxwyzfkoktumxlq0',
      active_account: true,
      onboarded_at: 'Jan 10, 2019',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: true,
      name: 'Robert Half International',
      total_spend: 295287.79,
      country: ['United States', 'United Kingdom'],
      categories_ai: [
        'Contractor Services',
        'Temporary Staffing',
        'Data Labeling',
      ],
      teams_ai: [
        '651 Workplace',
        '120 Software Engineering',
        'DNU Engineering Ops',
        'DNU Graphic Design',
        'DNU Growth',
        '661 People Experience',
        'DNU Quality',
        '380 Marketing Ops',
        '1020 Software Engineering',
        'DNU Big Data & Machine Learning',
        '320 Events',
        '3012 Growth',
        'DNU Exec Admin',
        '217 Desk SE',
        'DNU New Product Introduction',
        'DNU Sourcing',
        'DNU Business Operations',
        'DNU Events and Field Marketing',
        '130 Product Management',
        '216 Customer Success Operations',
      ],
    },
    {
      id: 'e2447cbb-f741-471f-bcdb-52be53f4dbfa',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/qz9bexprojnyuyhcvnhu',
      active_account: true,
      onboarded_at: 'Dec 23, 2020',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Helpware',
      total_spend: 330632.8,
      country: ['United States'],
      categories_ai: [
        'Customer Support',
        'Data Labeling',
        'Professional Services',
      ],
      teams_ai: ['410 Support', '413 L1 Support', '641 Information Technology'],
    },
    {
      id: '9ba52e50-528e-47aa-977f-a09066f168d1',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1408536546/upd2qxdpl0vby2702hxq.png',
      active_account: true,
      onboarded_at: 'June 16, 2022',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Apex Systems',
      total_spend: 91085.6,
      country: ['United States'],
      categories_ai: ['Contract Recruiting', 'Contractor Services'],
      teams_ai: [
        '682 Technical Recruiting',
        '321 Customer Marketing',
        '684 Recruiting Operations',
        '380 Marketing Ops',
      ],
    },
    {
      id: '17404093-50d2-4e58-b837-28dddd478b7a',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/mda6yedl2wsrktkglc64',
      active_account: true,
      onboarded_at: 'May 6, 2023',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'e-Zest',
      total_spend: 230440.0,
      country: ['United States'],
      categories_ai: ['Contractor Services'],
      teams_ai: ['649 Support Systems', '414 L2 Support'],
    },
  ],
  '24f59eee-3e7a-4312-94da-c057a9c1e224': [
    // Glasshouse Productions
    {
      id: '942e075b-012c-4cd0-a041-f1b4a6f71474',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1397762064/e3afc6caeeada65debec575083fc48d6.jpg',
      active_account: false,
      onboarded_at: 'Nov 4, 2019',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'AVTProductions',
      total_spend: 0.0,
      country: [],
      categories_ai: ['Event Production', 'AV Services'],
      teams_ai: ['DNU Events and Field Marketing'],
    },
    {
      id: '91c6ba9c-0417-4ebb-ba5f-cd0213df59d8',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/d41cb0e7214645f3b732976a42680582',
      active_account: true,
      onboarded_at: 'Apr 9, 2018',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'GES - Global Experience Specialists',
      total_spend: 1323817.64,
      country: ['United States', 'Canada'],
      categories_ai: ['Event Production', 'Booth Setup', 'Material Handling'],
      teams_ai: [
        '320 Events',
        'DNU Events and Field Marketing',
        '353 Canada Marketing',
      ],
    },
    {
      id: '3737454b-30b3-49cd-aee8-35347abbea19',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/ms5dd2qzjwl2xwkjzbxf',
      active_account: true,
      onboarded_at: 'Nov 21, 2022',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'John Murray Productions',
      total_spend: 0.0,
      country: ['United States'],
      categories_ai: ['Event Production'],
      teams_ai: ['320 Events', '3011 Events and Field Marketing'],
    },
    {
      id: 'ecd1151b-2e64-43ba-bb6e-2fc7058d6c86',
      image_url: 'https://logo.clearbit.com/wearewebb.com',
      active_account: true,
      onboarded_at: 'Oct 3, 2024',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'WEBB',
      total_spend: 198072.33,
      country: ['United States'],
      categories_ai: ['Event Production', 'Video Production'],
      teams_ai: ['213 Sales Exec', '320 Events'],
    },
  ],
  'c79f0b33-84e6-4298-bac8-d431c4fd9afe': [
    // PricewaterhouseCoopers
    {
      id: '5430f496-b914-49a8-bb22-c469e2300365',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/b6fixhtnkn5ugx6o5rl0',
      active_account: true,
      onboarded_at: 'June 8, 2020',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Crowe',
      total_spend: 978622.72,
      country: ['United Kingdom', 'United States'],
      categories_ai: ['Audit Services', 'SOX Compliance', 'Risk Assessment'],
      teams_ai: ['633 Technical Accounting', '630 AR', '610 Internal Audit'],
    },
    {
      id: 'f1f00536-7093-49c4-a80b-5dbb4eb95ac4',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1423529284/xkkm46kh2ggu4tldp5ib.png',
      active_account: true,
      onboarded_at: 'Apr 27, 2021',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Ernst & Young',
      total_spend: 108191.85,
      country: ['Mexico', 'India', 'United States'],
      categories_ai: ['Tax and Accounting Consulting'],
      teams_ai: [
        '638 Tax',
        '6033 Tax',
        '510 Fulfillment Ops',
        '221 Sales Operations',
        '633 Technical Accounting',
        '654 Commercial and Product Legal',
        '634 Payroll',
      ],
    },
    {
      id: '874c122d-f430-46b5-9d11-9b93e293f137',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1501089528/blyybcrxcn1of2xvigha.jpg',
      active_account: true,
      onboarded_at: 'Nov 29, 2018',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Deloitte',
      total_spend: 4309627.0,
      country: ['United States'],
      categories_ai: [
        'Audit Services',
        'Global Mobility Services',
        'Expat Tax Support',
      ],
      teams_ai: [
        '634 Payroll',
        '633 Technical Accounting',
        '631 AP',
        '661 People Experience',
        '636 Equity',
      ],
    },
    {
      id: '89da4969-efe6-4059-b8b9-1cd8709ab73d',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/zzdy3xiz7hdujeg5wwpx',
      active_account: true,
      onboarded_at: 'Aug 2, 2019',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'KPMG',
      total_spend: 1078483.43,
      country: [
        'United States',
        'Germany',
        'United Kingdom',
        'Netherlands',
        'France',
        'Poland',
      ],
      categories_ai: [
        'Tax Compliance',
        'Tax Consulting',
        'Financial Statement Services',
      ],
      teams_ai: [
        '638 Tax',
        '633 Technical Accounting',
        '6033 Tax',
        '6030 Corporate Accounting',
        '650 Legal',
        '630 AR',
        '321 Customer Marketing',
      ],
    },
  ],
  '6998aa8a-5a4e-45a5-a8ed-0cfb90c8374d': [
    // ZoomInfo
    {
      id: '9351f4ee-666c-44da-ad49-e043b8bd1283',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/sewfrwhyrjxmahwazbf8',
      active_account: true,
      onboarded_at: 'Sep 4, 2019',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Lusha',
      total_spend: 91282.93,
      country: ['United States'],
      categories_ai: ['Lead Generation', 'Prospect Intelligence'],
      teams_ai: ['380 Marketing Ops', '221 Sales Operations'],
    },
    {
      id: 'd6d02f93-b02b-4420-82e0-223701470db4',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/aadl1qwhrn26ykaca6pf',
      active_account: true,
      onboarded_at: 'Apr 20, 2022',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Leadspace',
      total_spend: 395700.0,
      country: ['United States'],
      categories_ai: [
        'Data Enrichment',
        'Salesforce Integration',
        'API Integration',
      ],
      teams_ai: ['380 Marketing Ops', 'DNU Growth'],
    },
    {
      id: '63ed4612-d981-42df-91fa-131f5eb31898',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/299541eb583641fca14884763666ea39',
      active_account: true,
      onboarded_at: 'Oct 30, 2024',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Clay',
      total_spend: 0.0,
      country: ['United States'],
      categories_ai: [
        'Data Enrichment',
        'Lead Generation',
        'Sales Intelligence',
      ],
      teams_ai: ['380 Marketing Ops'],
    },
  ],
  '47d65e8e-c38b-426b-bb5a-fccbdd4a990c': [
    // Enso
    {
      id: '162f0ef2-59b3-4076-995f-a9580c1293c8',
      image_url: 'https://logo.clearbit.com/epik.com.mx',
      active_account: true,
      onboarded_at: 'Jun 1, 2022',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Epik Events',
      total_spend: 768195.35,
      country: [],
      categories_ai: [
        'Event Production',
        'Promotional Materials',
        'Event Sponsorships',
      ],
      teams_ai: [
        '352 Mexico Marketing',
        '350 Growth Marketing',
        'DNU Events and Field Marketing',
      ],
    },
    {
      id: '50c97a22-d37d-4b0f-be09-7dc56ac5627f',
      image_url:
        'https://cdn.brandfetch.io/encumex.mx/icon/theme/light/fallback/transparent',
      active_account: false,
      onboarded_at: 'Mar 22, 2021',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Encumex, S.A. de C.V.',
      total_spend: 0.0,
      country: [],
      categories_ai: ['Marketing Services', 'Event Sponsorship'],
      teams_ai: ['320 Events'],
    },
  ],
  '5f656d08-7251-44bf-9ee5-faa70c569dca': [
    //Docusign
    {
      id: 'f125d1ca-0f2c-40ff-92b1-2e24f197acc8',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/qnbpcajqvlw8bkul1tru',
      active_account: true,
      onboarded_at: 'Feb 2, 2024',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Adobe',
      total_spend: 100761.04,
      country: [],
      categories_ai: [
        'Marketing Automation',
        'Email Deliverability Consulting',
      ],
      teams_ai: [
        'Marketing Ops',
        'Corporate Marketing',
        'Events',
        'Growth',
        'AP',
        'Finance & Supply Chain Systems',
        'Procurement',
        'Software Engineering',
      ],
    },
    {
      id: 'c26f8cad-70d4-4b6a-9366-6d9a13b6df98',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/jqeacbadxfgjkjctehtb',
      active_account: true,
      onboarded_at: 'Feb 2, 2024',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Ironclad',
      total_spend: 94092.14,
      country: [],
      categories_ai: ['Contract Management Software'],
      teams_ai: ['Legal'],
    },
  ],
  'b02e804a-1e7e-48e1-bb92-52e9f6e5f6c4': [
    //GitHub
    {
      id: '7241cd45-29a8-4ded-943d-5496fa3fc846',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/dvcq6aphn9unb1ypolhz',
      active_account: true,
      onboarded_at: 'Apr 10, 2024',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Atlassian',
      total_spend: 5344.15,
      country: [],
      categories_ai: [
        'Project Management Software',
        'Collaboration Software',
        'IT Operations Software',
      ],
      teams_ai: [
        'Information Technology',
        'Software Engineering',
        'Information Technology',
        'People Ops',
      ],
    },
    {
      id: 'd5f0aed1-30b1-4e03-9d02-5efe1735e8f2',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/ywjqppks5ffcnbfjuttq',
      active_account: false,
      onboarded_at: '',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Docker',
      total_spend: 0.0,
      country: [],
      categories_ai: ['Software Licenses', 'Developer Tools'],
      teams_ai: ['Software Engineering'],
    },
  ],
  '093326ed-7e61-40a0-b36f-6284b5febda0': [
    //Hilton
    {
      id: 'f1e3da14-b923-402c-99ef-1dc4504cb3a1',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1414561238/vmnotrcoyivei7hiwkhp.png',
      active_account: true,
      onboarded_at: 'Dec 1, 2023',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Hyatt',
      total_spend: 148619.09,
      country: [],
      categories_ai: [
        'Hotel Room Blocks',
        'Employee Travel Lodging',
        'Event Lodging',
      ],
      teams_ai: [
        'Events and Field Marketing',
        'AR',
        'Sales Engineering',
        'Exec Admin',
        'Sales Exec Admin',
        'Sales Exec',
        'Events',
        'Desk SE',
        'Operations Management',
        'Sales Engineering',
        'People Experience Programs',
        'G&A Exec Admin',
        'Fulfillment Operations',
        'Software Engineering',
        'Recruiting Operations',
      ],
    },
    {
      id: '7a45d190-7acd-4397-8c05-cc4b3cd14387',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/ub4dtit31eorsmhzueh8',
      active_account: true,
      onboarded_at: 'May 30, 2024',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Intercontinental Hotels Group',
      total_spend: 245418.24,
      country: [],
      categories_ai: ['Event Lodging', 'Team Offsites', 'Customer Events'],
      teams_ai: [
        'Events and Field Marketing',
        'Product Management',
        'Communications',
        'Corporate Recruiting',
        'Sales Engineering',
        'Corporate Marketing',
        'Desk SE',
        'People Experience',
        'Events and Field Marketing',
        'Sales Enablement',
        'Inside Sales',
        'Mid Market AE2',
      ],
    },
    {
      id: '517b3e16-4d4b-40d6-ade6-321202a7a42c',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1500471433/et2ammbolsqumg8xenbe.png',
      active_account: true,
      onboarded_at: 'Jan 10, 2024',
      active_contract: true,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Marriott International',
      total_spend: 3079762.53,
      country: [],
      categories_ai: ['Event Venues', 'Conference Venues', 'Team Offsites'],
      teams_ai: [
        'Procurement',
        'Fulfillment Operations',
        'Events',
        'Corporate Marketing',
        'Product Management',
        'Technical Exec Admin',
        'Events and Field Marketing',
        'Sales Exec',
        'EU Sales Engineering',
      ],
    },
  ],
  'e6acabc6-8e2e-4bc2-9d83-476972ebd108': [
    //Insight Global
    {
      id: '57e5c324-4b8e-49b9-9f08-24b264d7b40a',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/cdibfxwyzfkoktumxlq0',
      active_account: true,
      onboarded_at: 'Nov 15, 2023',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Robert Half International',
      total_spend: 199215.8,
      country: [],
      categories_ai: [
        'Contractor Services',
        'Temporary Staffing',
        'Data Labeling',
      ],
      teams_ai: [
        'Workplace',
        'Software Engineering',
        'Engineering Ops',
        'Graphic Design',
        'Growth',
        'People Experience',
        'Quality',
        'Marketing Ops',
        'Software Engineering',
        'Big Data & Machine Learning',
        'Events',
        'Growth',
        'Exec Admin',
        'Desk SE',
        'New Product Introduction',
        'Sourcing',
        'Business Operations',
        'Events and Field Marketing',
        'Product Management',
        'Customer Success Operations',
      ],
    },
    {
      id: 'c8988bfd-5512-4c5d-816c-61daf9263b70',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/pz2koyutuyggghxnrwig',
      active_account: true,
      onboarded_at: 'Dec 1, 2023',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Adecco',
      total_spend: 81861.65,
      country: [],
      categories_ai: ['Temporary Staffing'],
      teams_ai: ['Global Ops Engineering'],
    },
    {
      id: '0fc4470f-9ac1-4f9c-8f0d-f368c0f3a8e3',
      image_url:
        'https://images.crunchbase.com/image/upload/t_cb-default-original/v1397185068/5aaa60a64fcfb41345a790d9a001ea27.png',
      active_account: false,
      onboarded_at: '',
      active_contract: false,
      contract_expiring: false,
      manual_active_interaction: false,
      name: 'Randstad USA',
      total_spend: 0.0,
      country: [],
      categories_ai: ['Recruitment Services'],
      teams_ai: [],
    },
  ],
};
