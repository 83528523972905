import React, { useEffect, useState } from 'react';
import FilterChipAutocomplete from '../../common/FilterChipAutocomplete';
import StyledDivider from '../../common/StyledDivider';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { ReactComponent as Teams } from '../../common/icons/teams.svg';
import FilterFooter from '../../common/FilterFooter';
import { DEFAULT_REPORT_FILTERS } from './reportHelpers';
import ClearFiltersButton from '../../common/ClearFilters';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { updateFiltersReports } from '../../store/localStorage';
import CustomChip from '../../common/CustomChip';

const SPEND_STATUS_VALUES = [
  { label: 'In approval', value: 'requested' },
  { label: 'Committed', value: 'committed' },
  { label: 'Billed', value: 'billed' },
];

const ReportFilters = ({ filters, setFilters, filterData, page }) => {
  const [peopleData, setPeopleData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [filteredPeopleData, setFilteredPeopleData] = useState([]);
  const [dateRange, setDateRange] = useState(filters.timeframe || 30);

  const handleFilters = (newFilters) => {
    updateFiltersReports(newFilters);
    setFilters(newFilters);
  };

  const handlePeopleChange = (newValue) => {
    handleFilters({
      ...filters,
      people: newValue.map((item) => (item?.value ? item.value : item)),
    });
  };

  const handleTeamChange = (newValue) => {
    const team = newValue.map((item) => (item?.value ? item.value : item));
    const people = filters.people.length && newValue.length ? filters.people.filter((person) => {
      const personData = peopleData.find(item => item.value === person);
      return personData && team.includes(personData.team);
    }) : filters.people;

    handleFilters({
      ...filters,
      people,
      team,
    });
  };

  const resetFilters = () => {
    setDateRange(30);
    handleFilters(DEFAULT_REPORT_FILTERS);
  };

  useEffect(() => {
    if (!filterData) return;
    setPeopleData(filterData?.people);
    setTeamData(filterData?.team);
    setFilteredPeopleData(filterData?.people);
  }, [filterData]);

  useEffect(() => {
    if (!filters.team || !filters.team.length) {
      setFilteredPeopleData(peopleData);
      return;
    }
      const filteredPeople = peopleData.filter((person) => {
        return filters.team.includes(person.team);
      });
      setFilteredPeopleData(filteredPeople);
  }, [filters.team]);

  useEffect(() => {
    switch (dateRange) {
      case 30:
        handleFilters({
          ...filters,
          timeframe: 30,
          start_date: moment().subtract(30, 'day'),
          end_date: moment(),
        });
        break;
      case 60:
        handleFilters({
          ...filters,
          timeframe: 60,
          start_date: moment().subtract(60, 'day'),
          end_date: moment(),
        });
        break;
      case 90:
        handleFilters({
          ...filters,
          timeframe: 90,
          start_date: moment().subtract(90, 'day'),
          end_date: moment(),
        });
        break;
      case 180:
        handleFilters({
          ...filters,
          timeframe: 180,
          start_date: moment().subtract(180, 'day'),
          end_date: moment(),
        });
        break;
      case 365:
        handleFilters({
          ...filters,
          timeframe: 365,
          start_date: moment().subtract(365, 'day'),
          end_date: moment(),
        });
        break;
      case 'custom':
        handleFilters({
          ...filters,
          timeframe: 'custom',
        });
    }
  }, [dateRange]);

  const datePickerStyles = {
    textField: {
      sx: {
        borderRadius: '8px',
        borderWidth: '1px',
        borderColor: '#DEDBEC',
        '.MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '8px 0px 8px 12px',
          marginRight: '-8px',
        },
        '.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: '#6558B1 !important',
          },
        '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
          color: '#6558B1',
        },
        '.MuiButtonBase-root.MuiIconButton-root': {
          color: '#6558B173',
        },
      },
    },
    layout: {
      sx: {
        '.MuiPickersDay-root.Mui-selected': {
          color: '#fff',
          borderRadius: '100%',
          backgroundColor: '#6558B1 !important',
          transition: 'none',
        },
        '.MuiPickersYear-yearButton.Mui-selected': {
          color: '#fff',
          backgroundColor: '#6558B1 !important',
          transition: 'none',
        },
        '.MuiSvgIcon-root': {
          color: '#6558B173',
        },
      },
    },
  };

  return (
    <Box
      sx={{
        maxHeight: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '42px',
        gap: '24px',
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        margin: '24px 0 32px 24px',
        padding: '16px',
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #79747E29',
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.h6}
        >
          Filters
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'14px'}>
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel htmlFor="timeframe" color={'secondary'}>
              Date
            </InputLabel>
            <Select
              sx={{ borderRadius: '8px' }}
              id={'timeframe'}
              color={'secondary'}
              label={'Date'}
              value={dateRange}
              onChange={(event) => setDateRange(event.target.value)}
            >
              <MenuItem value={30}>Last 30 days</MenuItem>
              <MenuItem value={60}>Last 60 days</MenuItem>
              <MenuItem value={90}>Last 90 days</MenuItem>
              <MenuItem value={180}>Last 180 days</MenuItem>
              <MenuItem value={365}>Last 365 days</MenuItem>
              <MenuItem value={'custom'}>Custom</MenuItem>
            </Select>
          </FormControl>
          {dateRange === 'custom' && (
            <Box display={'flex'} gap={'8px'}>
              <DatePicker
                label="From"
                slotProps={datePickerStyles}
                format="MM/DD/YY"
                disableFuture
                id={'start_date'}
                value={moment(filters.start_date)}
                maxDate={moment(filters.end_date).subtract(1, 'days')}
                onChange={(newValue) =>
                  handleFilters({ ...filters, start_date: newValue })
                }
              />
              <DatePicker
                label="To"
                slotProps={datePickerStyles}
                format="MM/DD/YY"
                disableFuture
                id={'end_date'}
                value={moment(filters.end_date)}
                minDate={moment(filters.start_date).add(1, 'days')}
                onChange={(newValue) =>
                  handleFilters({ ...filters, end_date: newValue })
                }
              />
            </Box>
          )}
        </Box>

        <StyledDivider />
        {Boolean(teamData && teamData.length) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Teams
                </Box>
                <Box>
                  <Teams />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <FilterChipAutocomplete
                    options={teamData}
                    label="Team"
                    placeholder="Team"
                    onSelectionChange={handleTeamChange}
                    procurementMembers={filters.team}
                    showInitials={false}
                    restrictValues
                  />
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        {Boolean(peopleData && peopleData.length) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Employees
                </Box>
                <Box>
                  <AccountCircleOutlinedIcon color="icon" height={'16px'} />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <FilterChipAutocomplete
                    options={filteredPeopleData}
                    label="Employee"
                    placeholder="Employee"
                    onSelectionChange={handlePeopleChange}
                    procurementMembers={filters.people}
                    showInitials
                    restrictValues
                  />
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        {Boolean(page === 'spendingStats') && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Spend status
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  {SPEND_STATUS_VALUES.map((item) => (
                    <CustomChip
                      key={item.label}
                      label={item.label}
                      isActive={filters.status.includes(item.value)}
                      onClick={() => {
                        filters.status.includes(item.value)
                          ? handleFilters({
                              ...filters,
                              status: filters.status.filter(
                                (prevItem) => prevItem !== item.value,
                              ),
                            })
                          : handleFilters({
                              ...filters,
                              status: [...filters.status, item.value],
                            });
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        <Box>
          <ClearFiltersButton
            type={'big'}
            variant={'manual'}
            resetFunction={resetFilters}
          ></ClearFiltersButton>
        </Box>
      </Box>
      <FilterFooter />
    </Box>
  );
};

export default ReportFilters;
