import {
  AltRoute,
  CloseRounded,
  ForumOutlined,
  GroupsOutlined,
  Subject,
  Timeline,
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, IconButton, Link, Stack, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import DetailsNavigation from '../../common/DetailsNavigation';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import Logo from '../../common/Logo';
import NoResultCard from '../../common/NoResultCard';
import ShareLink from '../../common/ShareLink';
import StyledDivider from '../../common/StyledDivider';
import StyledIcon from '../../common/StyledIcon';
import SupplierBadges from '../../common/SupplierBadges';
import SupplierDetailsCompanyDetails from '../../common/SupplierDetailsCompanyDetails';
import { ReactComponent as ContractIcon } from '../../common/icons/contractIcon.svg';
import { ReactComponent as OpenInFull } from '../../common/icons/openInFull.svg';
import { appSlice } from '../../store/appSlice';
import { isBackofficePage, supplierResponseTime } from '../../utils/functions';
import InteractionEmployeeContacts from '../interactionDetails/InteractionEmployeeContacts';
import SupplierActivities from './SupplierActivities';
import SupplierActivityFullscreen from './SupplierActivityFullscreen';
import SupplierActivitySummary from './SupplierActivitySummary';
import SupplierAlternatives from './SupplierAlternatives';
import SupplierContracts from './SupplierContracts';
import SupplierDetailsAISummary from './SupplierDetailsAISummary';
import SupplierDetailsContacts from './SupplierDetailsContacts';
import SupplierDetailsSpend from './SupplierDetailsSpend';
import SupplierDetailsStructure from './SupplierDetailsStructure';
import SupplierDetailsTeams from './SupplierDetailsTeams';

const SupplierDetails = ({ close }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, pageId } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const hasDetailedActivityType = searchParams.get('activityType');
  const {
    setPage,
    setSearch,
    setPreviousSearch,
    addPreviousDetailsNavigation,
    setNextDetailsNavigation,
  } = appSlice.actions;
  const { search, page } = useSelector((state) => state.appSlice);

  const [supplierDetails, setSupplierDetails] = useState();
  const [responseTime, setRepsonseTime] = useState();
  const [interactions, setInteractions] = useState([]);
  const [combinedInteraction, setCombinedInteraction] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [supplierDetailsTab, setSupplierDetailsTab] = useState('1');
  const [invoiceCollection, setInvoiceCollection] = useState();
  const [purchaseOrderCollection, setPurchaseOrderCollection] = useState();
  const [requestCollection, setRequestCollection] = useState();

  const handleDetailsTabChange = (_, newValue) => {
    handleDetailsNavigation();
    navigate(
      `${
        page === 'overview' ? '' : `/${page}`
      }/details/supplier/${id}/${newValue}`,
    );
  };

  const handleDetailsNavigation = () => {
    dispatch(addPreviousDetailsNavigation(location.pathname));
    dispatch(setNextDetailsNavigation([]));
  };

  useEffect(() => {
    document.title = 'PartnerElement | Details > Supplier details';

    setIsLoading(true);
    getData().then((res) => {
      if (res !== 'error') {
        setSupplierDetails(res);
        setRepsonseTime(supplierResponseTime(res));
        const interactionIds = [...(res.interactions || [])].map(
          (item) => item.id,
        );
        getInteractions(interactionIds).then((data) => {
          setInteractions(data);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
    getData('invoices').then((res) => {
      if (res !== 'error') {
        setInvoiceCollection(res);
      } else {
        setInvoiceCollection([]);
      }
    });
    getData('purchase_orders').then((res) => {
      if (res !== 'error') {
        setPurchaseOrderCollection(res);
      } else {
        setPurchaseOrderCollection([]);
      }
    });
    getData('requests').then((res) => {
      if (res !== 'error') {
        setRequestCollection(res);
      } else {
        setRequestCollection([]);
      }
    });
  }, [id]);

  useEffect(() => {
    if (pageId) {
      setSupplierDetailsTab(pageId);
    } else {
      setSupplierDetailsTab('1');
    }
  }, [pageId]);

  useEffect(() => {
    if (!supplierDetails) return;
    const filteredStakeholders = filterInternalStakeholders(
      supplierDetails.stakeholders || [],
    );
    if (filteredStakeholders.length === 0) {
      setCombinedInteraction(null);
      return;
    }

    const reducedInteraction = {
      internal_stakeholders: filteredStakeholders,
    };
    setCombinedInteraction(reducedInteraction);
  }, [supplierDetails]);

  const filterInternalStakeholders = (res) => {
    const employees = res.filter((item) => item.department);
    const employeeDepartments = [
      ...new Set(employees.map((item) => item.department)),
    ].sort((a, b) => a?.localeCompare(b));
    return employeeDepartments
      .map((item) => ({
        type: item,
        items: res
          .filter((internal) => internal.department === item)
          .map((item) => ({
            ...item,
            id: item.email,
          })),
      }))
      .filter((item) => item.items.length !== 0);
  };

  const getInteractions = async (interactionIds) => {
    return await Promise.all(interactionIds.map((id) => getInteraction(id)));
  };

  const getData = async (dataType) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${id}/${dataType || ''}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const getInteraction = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/interactions/${id}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
        credentials: 'include',
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return 'error';
    }
  };

  const hasCategoryChips = () => {
    return Boolean(
      supplierDetails.categories && supplierDetails.categories.length !== 0,
    );
  };

  const hidePoweredBy = () => {
    return Boolean(
      !hasCategoryChips() &&
        !supplierDetails.foundation_year &&
        !supplierDetails.min_revenue_estimative &&
        !supplierDetails.num_employees_rang &&
        !supplierDetails.website &&
        !supplierDetails.headquarters &&
        (!supplierDetails.diversity_spotlights ||
          supplierDetails.diversity_spotlights.length === 0),
    );
  };

  const statsDepartments = [
    ...(supplierDetails?.financial_stats?.departments || []),
    ...(supplierDetails?.workflow_stats?.departments || []),
  ];
  const allDepartmentsInvolved = statsDepartments?.length
    ? statsDepartments
    : interactions
        ?.map((interaction) => interaction.departments_involved)
        .flat();
  const departmentsInvolved = [...new Set(allDepartmentsInvolved)].sort(
    (a, b) => a?.localeCompare(b),
  );

  const tabStyles = {
    textTransform: 'none',
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    paddingBottom: '10px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#49454F',
    '> .MuiTab-iconWrapper': {
      width: '56px',
      height: '24px',
      padding: '4px',
      borderRadius: '100px',
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.60)',
    },
    '&:hover': {
      '>.MuiTab-iconWrapper': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '&.Mui-selected': {
      color: '#1D1B20',
      fontWeight: '600',
      '>.MuiTab-iconWrapper': {
        background: '#E8DEF8',
        color: '#6558B1',
      },
    },
  };

  const tabPanelStyle = {
    paddingBottom: 0,
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '16px',
  };

  if (isLoading) {
    return <LoadingSkeleton variant={'supplierDetails'} close={close} />;
  }

  if (hasDetailedActivityType) {
    return (
      <Box>
        {!supplierDetails || isLoading ? (
          <LoadingSkeleton variant={'supplierDetails'} close={close} />
        ) : (
          <SupplierActivityFullscreen
            type={hasDetailedActivityType}
            supplier={supplierDetails}
            close={close}
            interactions={supplierDetails?.interactions}
            requests={requestCollection}
            handleClick={() => handleDetailsNavigation()}
            sidebar
          />
        )}
      </Box>
    );
  }

  return (
    <>
      {!supplierDetails ? (
        <Box
          sx={{
            minHeight: 'calc(100vh - 32px)',
            maxHeight: 'calc(100vh - 32px)',
            overflowY: 'auto',
            padding: '16px 24px',
            background: 'white',
            '@media (max-width: 768px)': {
              width: 'calc(100vw - 48px)',
            },
          }}
        />
      ) : (
        <Box
          sx={{
            minHeight: 'calc(100vh - 32px)',
            maxHeight: 'calc(100vh - 32px)',
            overflowY: 'auto',
            padding: '0',
            background: 'white',
            '@media (max-width: 768px)': {
              width: 'calc(100vw)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1.5px solid #694FA31A',
              padding: '16px 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                paddingLeft: '24px',
              }}
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              <DetailsNavigation handleClose={close} />
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.subtitle1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <Link
                  color={(theme) => theme.palette.text.main}
                  fontSize={(theme) => theme.typography.subtitle1}
                  sx={{ cursor: 'pointer', fontWeight: '500' }}
                  underline="none"
                  onClick={() => {
                    dispatch(setPage('suppliers'));
                    window.scrollTo(0, 0);
                    close();
                    navigate('/suppliers');
                  }}
                >
                  Suppliers
                </Link>
                <Box color={(theme) => theme.palette.text.secondary}>
                  {' '}
                  / {supplierDetails.name}
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} paddingRight={'24px'} gap={'8px'}>
              <ShareLink />
              {!isBackofficePage(location) && (
                <IconButton
                  type="button"
                  onClick={() => {
                    dispatch(setPreviousSearch(search));
                    dispatch(setSearch(''));
                    handleDetailsNavigation();
                    navigate(
                      `${
                        page === 'overview' ? '' : `/${page}`
                      }/details/supplier/${id}/fullscreen/${pageId || ''}`,
                    );
                  }}
                >
                  <StyledIcon component={OpenInFull} color={'icon'} />
                </IconButton>
              )}
              <IconButton type="button" onClick={close}>
                <StyledIcon component={CloseRounded} color={'icon'} />
              </IconButton>
            </Box>
          </Box>
          <TabContext value={supplierDetailsTab}>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <Box
                sx={{
                  borderBottom: 0,
                  padding: '16px 4px',
                  display: 'flex',
                  gap: '40px',
                  width: '80px',
                }}
              >
                <TabList
                  orientation="vertical"
                  onChange={handleDetailsTabChange}
                  textColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  aria-label="supplier details tabs"
                  role="navigation"
                  sx={{ minHeight: '0' }}
                >
                  <Tab
                    sx={tabStyles}
                    icon={<Subject color={'icon'} />}
                    label="Summary"
                    value="1"
                    disableRipple
                  />
                  <Tab
                    sx={tabStyles}
                    icon={<GroupsOutlined color={'icon'} />}
                    label="People"
                    value="2"
                    disableRipple
                  />
                  <Tab
                    sx={tabStyles}
                    icon={<ForumOutlined color={'icon'} />}
                    label="Activities"
                    value="3"
                    disableRipple
                  />
                  <Tab
                    sx={tabStyles}
                    icon={<ContractIcon />}
                    label="Contracts"
                    value="4"
                    disableRipple
                  />
                  <Tab
                    sx={tabStyles}
                    icon={<Timeline color={'icon'} />}
                    label="Structure & spend"
                    value="5"
                    disableRipple
                  />
                  <Tab
                    sx={tabStyles}
                    icon={<AltRoute color={'icon'} />}
                    label="Alternatives"
                    disableRipple
                    value="6"
                  />
                </TabList>
              </Box>
              <Box
                sx={{
                  paddingTop: '16px',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Stack
                    direction={'row'}
                    gap={'16px'}
                    alignItems={'center'}
                    sx={{ paddingLeft: '24px', marginBottom: '16px' }}
                  >
                    <Logo size={44} img={supplierDetails.image_url} />
                    <Box
                      fontSize={(theme) => theme.typography.h6}
                      color={(theme) => theme.palette.text.black}
                    >
                      {supplierDetails.name}
                    </Box>
                  </Stack>
                </Box>
                <SupplierDetailsAISummary supplier={supplierDetails} />
                <Box paddingLeft={'24px'} paddingBottom={'24px'}>
                  <SupplierBadges supplier={supplierDetails} />
                </Box>
                <StyledDivider />
                <TabPanel sx={tabPanelStyle} value="1">
                  {(!Boolean(hidePoweredBy()) ||
                    supplierDetails.annotated_perplexity) && (
                    <SupplierDetailsCompanyDetails
                      supplier={supplierDetails}
                      customStyle={{
                        marginBottom: '16px',
                        color: '#0F0030',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    />
                  )}
                  {Boolean(invoiceCollection?.length) && (
                    <Box marginBottom={'16px'} width={'648px'}>
                      <SupplierDetailsStructure
                        invoices={invoiceCollection}
                        timeframe={12}
                        supplierName={supplierDetails.name}
                        summary={() => {
                          handleDetailsTabChange('', '5');
                        }}
                      />
                    </Box>
                  )}
                  {Boolean(interactions.length) && (
                    <SupplierDetailsContacts
                      supplier={supplierDetails}
                      responseTime={responseTime}
                      miniView={() => {
                        handleDetailsTabChange('', '2');
                      }}
                    />
                  )}
                  {Boolean(departmentsInvolved.length) && (
                    <Box
                      sx={{
                        marginTop: '16px',
                        marginBottom: '16px',
                      }}
                    >
                      <SupplierDetailsTeams
                        teams={departmentsInvolved}
                        handleClick={() => {
                          handleDetailsTabChange('', '2');
                        }}
                      />
                    </Box>
                  )}
                  {Boolean(
                    supplierDetails?.interactions?.length ||
                      requestCollection?.length,
                  ) && (
                    <SupplierActivitySummary
                      interactions={supplierDetails?.interactions}
                      requests={requestCollection}
                      handleClick={() => {
                        handleDetailsTabChange('', '3');
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel sx={tabPanelStyle} value="2">
                  {Boolean(
                    supplierDetails?.stakeholders?.length ||
                      supplierDetails?.contacts?.length,
                  ) ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      width={'100%'}
                    >
                      {combinedInteraction && (
                        <InteractionEmployeeContacts
                          interaction={combinedInteraction}
                          supplierView
                        />
                      )}
                      {supplierDetails?.contacts?.length > 0 && (
                        <SupplierDetailsContacts
                          supplier={supplierDetails}
                          responseTime={responseTime}
                        />
                      )}
                    </Box>
                  ) : (
                    <NoResultCard variant="supplier-people" />
                  )}
                </TabPanel>
                <TabPanel sx={tabPanelStyle} value="3">
                  <SupplierActivities
                    handleClick={() => handleDetailsNavigation()}
                    interactions={supplierDetails?.interactions || []}
                    requests={requestCollection || []}
                  />
                </TabPanel>
                <TabPanel sx={tabPanelStyle} value="4">
                  <SupplierContracts
                    variant={'sidebar'}
                    supplierId={supplierDetails.id}
                  />
                </TabPanel>
                <TabPanel sx={tabPanelStyle} value="5">
                  <Box>
                    {Boolean(invoiceCollection && purchaseOrderCollection) && (
                      <SupplierDetailsSpend
                        invoices={invoiceCollection}
                        purchase_orders={purchaseOrderCollection}
                        supplierName={supplierDetails.name}
                        sidebar
                      />
                    )}
                  </Box>
                </TabPanel>
                <TabPanel sx={tabPanelStyle} value="6">
                  <SupplierAlternatives
                    supplierId={supplierDetails.id}
                    variant={'sidebar'}
                    handleClick={() => handleDetailsNavigation()}
                  />
                </TabPanel>
              </Box>
            </Box>
          </TabContext>
        </Box>
      )}
    </>
  );
};

export default SupplierDetails;
