import styled from '@emotion/styled';
import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { appSlice } from "../store/appSlice";
import {
  updateFiltersBundles,
  updateFiltersInteractions,
  updateFiltersOverview,
} from "../store/localStorage";
import {
  defaultFiltersBundles,
  defaultFiltersInteractions,
  defaultFiltersOverview,
  defaultFiltersSuppliers,
} from '../utils/constants';
import { hasRedesignedHomepage } from '../utils/functions';

const StyledClearFiltersButton = styled(Button)({
    textTransform: "none",
});

const StyledBigClearFiltersButton = styled(Button)(
    ({ theme }) => ({
        textTransform: "none",
        color: theme.palette.secondary.main,
        border: "1px solid #DEDBEC",
        width: "100%",
        fontSize: "15px",
        fontWeight: "500",
        borderRadius: "12px",
        paddingY: "8px",
    })
);

const ClearFiltersButton = ({
  variant = 'search',
  customText,
  type = 'small',
  resetFunction,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    setSearch,
    setFiltersOverview,
    setFiltersInteractions,
    setFiltersBundles,
    setFiltersSuppliers,
  } = appSlice.actions;

  const { user } =
  useSelector((state) => state.appSlice);

    const defaultText = "Clear filters";

  const clearFilters = () => {
    switch (variant) {
      case 'search': {
        dispatch(setSearch(''));
        break;
      }
      case 'filtersOverview': {
        updateFiltersOverview(defaultFiltersOverview);
        dispatch(setFiltersOverview(defaultFiltersOverview));
        break;
      }
      case 'filtersInteractions': {
        updateFiltersInteractions(defaultFiltersInteractions);
        dispatch(setFiltersInteractions(defaultFiltersInteractions));
        break;
      }
      case 'filtersBundles': {
        updateFiltersBundles(defaultFiltersBundles);
        dispatch(setFiltersBundles(defaultFiltersBundles));
        dispatch(setSearch(''));
        break;
      }
      case 'filtersSuppliers': {
        if (hasRedesignedHomepage(user)) {
          dispatch(setFiltersSuppliers(defaultFiltersSuppliers));
        } else {
          dispatch(setFiltersSuppliers({...defaultFiltersSuppliers, active_account: 'null'}));
        }
        break;
      }
      case 'manual': {
        resetFunction && resetFunction();
        break;
      }
      default:
        break;
    }
  };

    return (
        type === "small" ?
        <StyledClearFiltersButton onClick={clearFilters} color="secondary" {...props}>
            {customText || defaultText}
        </StyledClearFiltersButton> :

        <StyledBigClearFiltersButton onClick={clearFilters} {...props}>
            {customText || defaultText}
        </StyledBigClearFiltersButton>
    );
};

export default ClearFiltersButton;
