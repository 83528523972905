import {
  ChangeCircleRounded,
  CheckCircleRounded,
  TaskRounded,
} from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearFiltersButton from '../common/ClearFilters';
import CustomChip from '../common/CustomChip';
import DealValueRange from '../common/DealValueRange';
import FilterChipAutocomplete from '../common/FilterChipAutocomplete';
import FilterFooter from '../common/FilterFooter';
import { ReactComponent as OnboardedSupplier } from '../common/icons/onboardedSupplier.svg';
import { ReactComponent as DealValue } from '../common/icons/request_quote.svg';
import { ReactComponent as Teams } from '../common/icons/teams.svg';
import InfoMessage from '../common/InfoMessage';
import StyledDivider from '../common/StyledDivider';
import StyledIcon from '../common/StyledIcon';
import { appSlice } from '../store/appSlice';
import {
  updateFiltersInteractions,
  updateFiltersOverview,
} from '../store/localStorage';
import { getDealValueRanges, getDepartments } from '../store/storeFunctions';
import {
  defaultFiltersInteractions,
  defaultFiltersOverview,
  filterEngagementStage,
} from '../utils/constants';
import { hasRedesignedHomepage } from '../utils/functions';

const defaultFiltersVariant = (page) => {
  switch (page) {
    case 'overview': {
      return defaultFiltersOverview;
    }
    case 'interactions': {
      return defaultFiltersInteractions;
    }
    default:
      return defaultFiltersInteractions;
  }
};

const Filters = () => {
  const dispatch = useDispatch();
  const {
    filtersOverview,
    filtersInteractions,
    departments,
    procurementFilterData,
    dealValueRanges,
    page,
    user,
  } = useSelector((state) => state.appSlice);
  const { setFiltersOverview, setFiltersInteractions } = appSlice.actions;

  const [filters, setFilters] = useState(defaultFiltersVariant(page));

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getDealValueRanges());
  }, [dispatch]);

  useEffect(() => {
    switch (page) {
      case 'overview': {
        setFilters(filtersOverview);
        break;
      }
      case 'interactions': {
        setFilters(filtersInteractions);
        break;
      }
      default:
        setFilters(defaultFiltersInteractions);
        break;
    }
  }, [page, filtersOverview, filtersInteractions]);

  const handleFilters = (newFilters) => {
    switch (page) {
      case 'overview': {
        updateFiltersOverview(newFilters);
        dispatch(setFiltersOverview(newFilters));
        break;
      }
      case 'interactions': {
        updateFiltersInteractions(newFilters);
        dispatch(setFiltersInteractions(newFilters));
        break;
      }
      default:
        break;
    }
  };

  const handleProcurementMembersChange = (newValue) => {
    handleFilters({
      ...filters,
      procurementMembers: newValue.map((item) =>
        item?.value ? item.value : item,
      ),
    });
  };

  const handleProcurementTeamsChange = (newValue) => {
    handleFilters({
      ...filters,
      procurementTeams: newValue.map((item) =>
        item?.value ? item.value : item,
      ),
    });
  };

  return (
    <Box
      sx={{
        maxHeight: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '42px',
        gap: '24px',
        border: '1px solid #DEDBEC',
        borderRadius: '12px',
        margin: '24px 0 32px 24px',
        padding: '16px',
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          paddingBottom: '24px',
          borderBottom: '1px solid #79747E29',
        }}
      >
        <Box
          color={(theme) => theme.palette.text.main}
          fontSize={(theme) => theme.typography.h6}
        >
          Filters
        </Box>
        <Box>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel htmlFor="timeframe" color={'secondary'}>
              Include messages from
            </InputLabel>
            <Select
              sx={{ borderRadius: '10px' }}
              id={'timeframe'}
              color={'secondary'}
              label={'Include messages from'}
              value={filters.timeframe}
              onChange={(event) =>
                handleFilters({ ...filters, timeframe: event.target.value })
              }
            >
              <MenuItem value={7}>Last 7 days</MenuItem>
              <MenuItem value={30}>Last 30 days</MenuItem>
              <MenuItem value={60}>Last 60 days</MenuItem>
              <MenuItem value={90}>Last 90 days</MenuItem>
              <MenuItem value={180}>Last 180 days</MenuItem>
              <MenuItem value={365}>Last 365 days</MenuItem>
              <MenuItem value={'alltime'}>All time</MenuItem>
            </Select>
          </FormControl>
          {user?.last_sync_at && (
            <Box
              fontSize={(theme) => theme.typography.body2}
              color={(theme) => theme.palette.text.medium}
              marginTop={'8px'}
            >
              Last sync {moment(user?.last_sync_at).format('MMMM DD, YYYY')}
            </Box>
          )}
        </Box>

        <StyledDivider />
        {Boolean(
          procurementFilterData?.departments &&
            procurementFilterData?.departments.length,
        ) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Teams
                </Box>
                <Box>
                  <Teams />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <FilterChipAutocomplete
                    options={procurementFilterData?.departments}
                    label="Team"
                    placeholder="Team"
                    onSelectionChange={handleProcurementTeamsChange}
                    procurementMembers={filters.procurementTeams}
                    showInitials={false}
                    restrictValues
                  />
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        {Boolean(
          procurementFilterData?.internal_stakeholders &&
            procurementFilterData?.internal_stakeholders.length,
        ) && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Employees
                </Box>
                <Box>
                  <AccountCircleOutlinedIcon color="icon" height={'16px'} />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                  <FilterChipAutocomplete
                    options={procurementFilterData?.internal_stakeholders}
                    label="Employee"
                    placeholder="Employee"
                    onSelectionChange={handleProcurementMembersChange}
                    procurementMembers={filters.procurementMembers}
                    showInitials
                    restrictValues
                  />
                </Box>
              </Box>
            </Box>
            <StyledDivider />
          </>
        )}
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Stages
            </Box>
            <Box>
              <InfoMessage
                message={
                  'Select a stage to filter interactions by exploration, negotiation, or closing stages.'
                }
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {filterEngagementStage.map((item) => (
              <CustomChip
                key={item.label}
                label={item.label}
                isActive={filters.engagementStage.includes(item.value)}
                onClick={() => {
                  filters.engagementStage.includes(item.value)
                    ? handleFilters({
                        ...filters,
                        engagementStage: filters.engagementStage.filter(
                          (prevItem) => prevItem !== item.value,
                        ),
                      })
                    : handleFilters({
                        ...filters,
                        engagementStage: [
                          ...filters.engagementStage,
                          item.value,
                        ],
                      });
                }}
              />
            ))}
          </Box>
        </Box>

        <StyledDivider />

        <Box>
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Procurement engaged
            </Box>
            <StyledIcon component={CheckCircleRounded} color="icon_success" />
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filters.procurementInvolved || null}
                onChange={(event) =>
                  handleFilters({
                    ...filters,
                    procurementInvolved: event.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label={'Both'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        <StyledDivider />

        <Box>
          <Stack
            marginBottom={'2px'}
            direction={'row'}
            spacing={'6px'}
            alignItems={'center'}
          >
            <Box
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.medium}
            >
              Renewals
            </Box>
            <StyledIcon component={ChangeCircleRounded} color="icon_warning" />
          </Stack>
          <Box>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filters.renewals}
                onChange={(event) =>
                  handleFilters({ ...filters, renewals: event.target.value })
                }
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'secondary'} />}
                  label="Yes"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'secondary'} />}
                  label="No"
                />{' '}
                <FormControlLabel
                  value={'null'}
                  control={<Radio color={'secondary'} />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        <StyledDivider />

        {dealValueRanges?.length !== 0 && (
          <>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  gap: '8px',
                }}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                  paddingBottom={'8px'}
                >
                  Deal value
                </Box>
                <Box>
                  <DealValue />
                </Box>
              </Box>
              <Stack>
                <DealValueRange
                  breakpoints={dealValueRanges}
                  selectedRanges={filters.dealValueRanges || []}
                  onRangeSelect={(value) => {
                    handleFilters({
                      ...filters,
                      dealValueRanges: value,
                    });
                  }}
                />
              </Stack>
            </Box>
            <StyledDivider />
          </>
        )}

        {page === 'interactions' && (
          <>
            <Box>
              <Stack
                marginBottom={'2px'}
                direction={'row'}
                spacing={'6px'}
                alignItems={'center'}
              >
                <Box
                  fontSize={(theme) => theme.typography.body1}
                  color={(theme) => theme.palette.text.medium}
                >
                  Deal closed
                </Box>
                <StyledIcon component={TaskRounded} color="secondary" />
              </Stack>
              <Box>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={filters.deal_closed}
                    onChange={(event) => {
                      handleFilters({
                        ...filters,
                        deal_closed: event.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={'true'}
                      control={<Radio color={'secondary'} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={'false'}
                      control={<Radio color={'secondary'} />}
                      label="No"
                    />{' '}
                    <FormControlLabel
                      value={'null'}
                      control={<Radio color={'secondary'} />}
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <StyledDivider />
            {hasRedesignedHomepage(user) && (
              <Box>
                <Stack
                  marginBottom={'2px'}
                  direction={'row'}
                  spacing={'6px'}
                  alignItems={'center'}
                >
                  <Box
                    fontSize={(theme) => theme.typography.body1}
                    color={(theme) => theme.palette.text.medium}
                  >
                    Onboarded supplier
                  </Box>
                  <StyledIcon component={OnboardedSupplier} />
                </Stack>
                <Box>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={filters.supplierOnboarded || 'null'}
                      onChange={(event) =>
                        handleFilters({
                          ...filters,
                          supplierOnboarded: event.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value={'true'}
                        control={<Radio color={'secondary'} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio color={'secondary'} />}
                        label="No"
                      />{' '}
                      <FormControlLabel
                        value={'null'}
                        control={<Radio color={'secondary'} />}
                        label={'Both'}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            )}
            <StyledDivider />
          </>
        )}

        <Box>
          <ClearFiltersButton
            type={'big'}
            variant={
              page === 'overview' ? 'filtersOverview' : 'filtersInteractions'
            }
          ></ClearFiltersButton>
        </Box>
      </Box>
      <FilterFooter />
    </Box>
  );
};

export default Filters;
