import { Box, Link } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import CustomCard from '../../common/CustomCard';
import InfoMessageHover from '../../common/InfoMessageHover';

const COLORS = ['#FFE7B3', '#FDAA63', '#FF808B', '#F3D03E', '#80E0A7'];

const infoMessage = {
  team: 'Total approved invoice spend by NetSuite cost center.',
  category:
    'Total approved invoice spend by NetSuite account (matches to Zip category).',
};

const CustomTooltip = ({ active, payload, amountLabel, percentLabel }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        fontSize={(theme) => theme.typography.body2}
        color={(theme) => theme.palette.text.medium}
        sx={{
          position: 'relative',
          background: 'white',
          padding: '4px 12px',
          borderRadius: '6px',
          boxShadow:
            '0px 1.850000023841858px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)',
          top: '-64px',
          '::after': {
            content: "''",
            position: 'absolute',
            width: '14px',
            height: '14px',
            background: 'white',
            transform: 'translateX(-50%) translateY(-50%) rotate(-45deg)',
            top: '100%',
            left: '50%',
            boxShadow:
              '0px 1.850000023841858px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)',
            clipPath: 'polygon(0 100%, 0 0, 100% 100%, 0 100%)',
          },
        }}
      >
        {`${payload[0].payload.label} - ${payload[0].payload[amountLabel]} (${payload[0].payload[percentLabel]})`}
      </Box>
    );
  }

  return null;
};

const DashboardPieChart = ({ data, type, handleClick }) => {
  const label = `Spend by ${type}`;
  const amountLabel =
    type === 'team' ? 'billedFormatted' : 'total_spend_amount_usd';
  const percentLabel = type === 'team' ? 'billedPercent' : 'percent';
  const dataKey = type === 'team' ? 'billed' : 'total_spend_amount';
  return (
    <CustomCard minHeight={'320px'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'100%'}
      >
        <Box
          minHeight={'250px'}
          padding={'16px 16px 0'}
          display={'flex'}
          gap={'16px'}
        >
          <Box
            width={'50%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
          >
            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.h6}
              >
                {label}
              </Box>
              <InfoMessageHover
                positionVariant={'bottom-right'}
                message={`${infoMessage[type]}`}
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
              {data.map((spend, index) => (
                <Box
                  key={`by-team-${spend.label}-${index}`}
                  display={'flex'}
                  flexDirection={'row'}
                  gap={'8px'}
                >
                  <Box>
                    <svg width="8" height="8">
                      <circle
                        cx="4"
                        cy="4"
                        r="4"
                        fill={spend.color || COLORS[index]}
                      />
                    </svg>
                  </Box>
                  <Box
                    color={(theme) => theme.palette.text.black}
                    fontSize={(theme) => theme.typography.body2}
                    width={'120px'}
                    sx={{
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {spend.label}
                  </Box>
                  <Box
                    color={(theme) => theme.palette.text.main}
                    fontSize={(theme) => theme.typography.body2}
                  >
                    {spend[amountLabel]}
                  </Box>
                  <Box
                    color={(theme) => theme.palette.text.medium}
                    fontSize={(theme) => theme.typography.body2}
                  >
                    {`(${spend[percentLabel]})`}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box width={'50%'}>
            <ResponsiveContainer width={'99%'}>
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={90}
                  innerRadius={40}
                  fill="#8884d8"
                  dataKey={dataKey}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color || COLORS[index]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  content={
                    <CustomTooltip
                      amountLabel={amountLabel}
                      percentLabel={percentLabel}
                    />
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box padding={'16px'}>
          {Boolean(handleClick) && (
            <Link
              color="secondary"
              sx={{ cursor: 'pointer' }}
              underline="hover"
              onClick={handleClick}
              fontSize={'14px'}
              fontWeight={'500'}
            >
              See all suppliers
            </Link>
          )}
        </Box>
      </Box>
    </CustomCard>
  );
};

export default DashboardPieChart;
