import { ArrowForwardRounded } from '@mui/icons-material';
import { Box, IconButton, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCard from '../../common/CustomCard';
import InfoMessageHover from '../../common/InfoMessageHover';
import StyledIcon from '../../common/StyledIcon';
import { appSlice } from '../../store/appSlice';
import { defaultFiltersInteractions } from '../../utils/constants';
import { formatCurrency } from '../../utils/functions';
import { DashboardInteractionFilters } from './DashboardFilters';

const DashboardOpenInteractions = ({
  interactions,
  handleClick,
  teams,
  filters,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const [openInteractions, setOpenInteractions] = useState([]);
  const [dealValueMax, setDealValueMax] = useState();
  const [highValueTotal, setHighValueTotal] = useState(0);
  const [newSuppliers, setNewSuppliers] = useState(0);
  const [renewalTotal, setRenewalTotal] = useState(0);
  const { setFiltersInteractions } = appSlice.actions;

  const { dealValueRanges } = useSelector((state) => state.appSlice);

  const setRenewalFilter = () => {
    dispatch(
      setFiltersInteractions({
        ...defaultFiltersInteractions,
        timeframe: filters.interactionTimeframe,
        procurementTeams: teams || [],
        renewals: 'true',
      }),
    );
  };

  const setHighValueFilter = () => {
    dispatch(
      setFiltersInteractions({
        ...defaultFiltersInteractions,
        timeframe: filters.interactionTimeframe,
        procurementTeams: teams || [],
        renewals: 'null',
        dealValueRanges: dealValueMax ? [`${dealValueMax},`] : [],
      }),
    );
  };

  const setNewSupplierFilter = () => {
    dispatch(
      setFiltersInteractions({
        ...defaultFiltersInteractions,
        timeframe: filters.interactionTimeframe,
        renewals: 'false',
        procurementTeams: teams || [],
        supplierOnboarded: 'false',
      }),
    );
  };

  const setViewAllInteractions = () => {
    dispatch(
      setFiltersInteractions({
        ...defaultFiltersInteractions,
        timeframe: filters.interactionTimeframe,
        renewals: 'null',
        procurementTeams: teams || [],
      }),
    );
  };

  useEffect(() => {
    if (!interactions?.length) return;
    const filteredInteractions = interactions.filter(
      (item) => !item.deal_closed,
    );
    const exploration = filteredInteractions
      .filter((item) => item.stage_name === 'Exploration')
      .reduce(
        (acc, curr) => {
          return {
            count: acc.count + 1,
            value: acc.value + Number(curr.deal_value || 0),
          };
        },
        { count: 0, value: 0 },
      );
    const negotiation = filteredInteractions
      .filter((item) => item.stage_name === 'Negotiation')
      .reduce(
        (acc, curr) => {
          return {
            count: acc.count + 1,
            value: acc.value + Number(curr.deal_value || 0),
          };
        },
        { count: 0, value: 0 },
      );
    const closing = filteredInteractions
      .filter((item) => item.stage_name === 'Closing')
      .reduce(
        (acc, curr) => {
          return {
            count: acc.count + 1,
            value: acc.value + Number(curr.deal_value || 0),
          };
        },
        { count: 0, value: 0 },
      );
    setOpenInteractions({
      exploration,
      negotiation,
      closing,
      total: filteredInteractions.length,
    });
    const renewals = filteredInteractions.filter((item) => item.renewal);
    const seenSuppliers = [];
    const newSuppliers = filteredInteractions.filter((item) => {
      if (seenSuppliers.includes(item.supplier_id)) return false;
      seenSuppliers.push(item.supplier_id);
      return !item.supplier_onboarded && !item.deal_closed && !item.renewal;
    });
    setRenewalTotal(renewals.length);
    setNewSuppliers(newSuppliers.length);
  }, [interactions]);

  useEffect(() => {
    if (!dealValueRanges?.length) return;
    if (!interactions?.length) return;
    const dealValue = dealValueRanges[dealValueRanges.length - 1];
    setHighValueTotal(
      interactions.filter(
        (item) => Number(item.deal_value) >= dealValue && !item.deal_closed,
      ).length,
    );
    setDealValueMax(dealValue);
  }, [dealValueRanges, interactions]);

  return (
    <CustomCard>
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        padding={'16px'}
        gap={'16px'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h5}
          >
            {`Happening now (${openInteractions.total || 0})`}
          </Box>
          <DashboardInteractionFilters
            filters={filters}
            setFilters={setFilters}
          />
        </Box>
        <Box display={'flex'}>
          <Box
            display={'flex'}
            color={'#251A00'}
            fontSize={(theme) => theme.typography.subtitle1}
            width={'33%'}
            height={'56px'}
            backgroundColor={'#C9F7D5'}
            sx={{ opacity: '0.7' }}
            padding={'16px'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={'4px 0 0 4px'}
          >
            {`${openInteractions?.exploration?.count || 0} (${formatCurrency(
              openInteractions?.exploration?.value || 0,
            )})`}
          </Box>
          <Box
            display={'flex'}
            color={'#251A00'}
            fontSize={(theme) => theme.typography.subtitle1}
            width={'33%'}
            height={'56px'}
            backgroundColor={'#FFE7B3'}
            sx={{ opacity: '0.7' }}
            padding={'16px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {`${openInteractions?.negotiation?.count || 0} (${formatCurrency(
              openInteractions?.negotiation?.value || 0,
            )})`}
          </Box>
          <Box
            display={'flex'}
            color={'#251A00'}
            fontSize={(theme) => theme.typography.subtitle1}
            width={'33%'}
            height={'56px'}
            backgroundColor={'#FFDBD2'}
            sx={{ opacity: '0.7' }}
            padding={'16px'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={'0 4px 4px 0'}
          >
            {`${openInteractions?.closing?.count || 0} (${formatCurrency(
              openInteractions?.closing?.value || 0,
            )})`}
          </Box>
        </Box>
        <Box display={'flex'}>
          <Box
            display={'flex'}
            color={(theme) => theme.palette.text.secondary}
            fontSize={(theme) => theme.typography.body2}
            width={'33%'}
            justifyContent={'center'}
            alignItems={'center'}
            marginTop={'-12px'}
            padding={'0 16px'}
          >
            Exploration
          </Box>
          <Box
            display={'flex'}
            color={(theme) => theme.palette.text.secondary}
            fontSize={(theme) => theme.typography.body2}
            width={'33%'}
            justifyContent={'center'}
            alignItems={'center'}
            marginTop={'-12px'}
            padding={'0 16px'}
          >
            Negotiation
          </Box>
          <Box
            display={'flex'}
            color={(theme) => theme.palette.text.secondary}
            fontSize={(theme) => theme.typography.body2}
            width={'33%'}
            justifyContent={'center'}
            alignItems={'center'}
            marginTop={'-12px'}
            padding={'0 16px'}
          >
            Closing
          </Box>
        </Box>
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} gap={'16px'}>
          <CustomCard
            isHover
            onClick={() => {
              setHighValueFilter();
              handleClick();
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding={'16px'}
            >
              <Box display={'flex'} gap={'16px'} alignItems={'flex-end'}>
                <Box
                  color={(theme) => theme.palette.text.primary}
                  fontSize={(theme) => theme.typography.h4}
                >
                  {highValueTotal}
                </Box>
                <Box
                  color={(theme) => theme.palette.text.primary}
                  fontSize={(theme) => theme.typography.body1}
                  paddingBottom={'4px'}
                  display={'flex'}
                  gap={'8px'}
                  alignItems={'center'}
                >
                  <Box>High value</Box>
                  <InfoMessageHover
                    message={
                      'High-value interactions involve deals exceeding the upper threshold set by your admin in Settings'
                    }
                  />
                </Box>
              </Box>
              <Box>
                <IconButton type="button" sx={{ padding: '8px' }}>
                  <StyledIcon component={ArrowForwardRounded} color={'icon'} />
                </IconButton>
              </Box>
            </Box>
          </CustomCard>
          <CustomCard
            isHover
            onClick={() => {
              setRenewalFilter();
              handleClick();
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding={'16px'}
            >
              <Box display={'flex'} gap={'16px'} alignItems={'flex-end'}>
                <Box
                  color={(theme) => theme.palette.text.primary}
                  fontSize={(theme) => theme.typography.h4}
                >
                  {renewalTotal}
                </Box>
                <Box
                  color={(theme) => theme.palette.text.primary}
                  fontSize={(theme) => theme.typography.body1}
                  paddingBottom={'4px'}
                >
                  Renewal
                </Box>
              </Box>
              <Box>
                <IconButton type="button" sx={{ padding: '8px' }}>
                  <StyledIcon component={ArrowForwardRounded} color={'icon'} />
                </IconButton>
              </Box>
            </Box>
          </CustomCard>
          <CustomCard
            isHover
            onClick={() => {
              setNewSupplierFilter();
              handleClick();
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding={'16px'}
            >
              <Box display={'flex'} gap={'16px'} alignItems={'flex-end'}>
                <Box
                  color={(theme) => theme.palette.text.primary}
                  fontSize={(theme) => theme.typography.h4}
                >
                  {newSuppliers}
                </Box>
                <Box
                  color={(theme) => theme.palette.text.primary}
                  fontSize={(theme) => theme.typography.body1}
                  paddingBottom={'4px'}
                >
                  New supplier
                </Box>
              </Box>
              <Box>
                <IconButton type="button" sx={{ padding: '8px' }}>
                  <StyledIcon component={ArrowForwardRounded} color={'icon'} />
                </IconButton>
              </Box>
            </Box>
          </CustomCard>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Link
            color="secondary"
            sx={{ cursor: 'pointer' }}
            underline="hover"
            onClick={() => {
              setViewAllInteractions();
              handleClick();
            }}
            fontSize={'14px'}
            fontWeight={'500'}
            marginRight={'32px'}
          >
            View all
          </Link>
        </Box>
      </Box>
    </CustomCard>
  );
};

export default DashboardOpenInteractions;
