import { Box, Link } from '@mui/material';
import CustomCard from '../../common/CustomCard';
import InteractionItem from '../../common/InteractionItem';
import ActivityItem from '../../common/ActivityItem';
import { useState } from 'react';
import DashboardMyActivityModal from './DashboardMyActivityModal';

const DashboardMyActivity = ({ activities }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  if (
    !activities ||
    (!activities.current_supplier.length && !activities.new_supplier.length)
  )
    return <></>;
  return (
    <CustomCard>
      <Box
        padding={'16px'}
        display={'grid'}
        gridTemplateColumns={'1fr 1fr'}
        gap={'16px'}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          gridColumn={'1 / -1'}
        >
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h5}
          >
            My activity
          </Box>
          <Link
            color="secondary"
            sx={{ cursor: 'pointer' }}
            underline="hover"
            onClick={toggleModal}
          >
            View all
          </Link>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            {`New suppliers
            (${activities.new_supplier.length})`}
          </Box>
          <>
            {activities.new_supplier.slice(0, 3).map((activity) => {
              if (activity.record_type === 'interaction')
                return (
                  <InteractionItem
                    variant={'Dashboard'}
                    interaction={activity}
                  />
                );
              return <ActivityItem activity={activity} />;
            })}
          </>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) => theme.typography.h6}
          >
            {`Existing suppliers (${activities.current_supplier.length})`}
          </Box>
          <>
            {activities.current_supplier.slice(0, 3).map((activity) => {
              if (activity.record_type === 'interaction')
                return (
                  <InteractionItem
                    variant={'Dashboard'}
                    interaction={activity}
                  />
                );
              return <ActivityItem activity={activity} />;
            })}
          </>
        </Box>
      </Box>
      <DashboardMyActivityModal activities={activities} open={modalOpen} handleClose={toggleModal} />
    </CustomCard>
  );
};

export default DashboardMyActivity;
