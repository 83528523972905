import { Box, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InteractionItem from '../common/InteractionItem';
import LoadingSkeleton from '../common/LoadingSkeleton';
import NoResultCard from '../common/NoResultCard';
import { appSlice } from '../store/appSlice';
import { generateEmployeeFilters, getInteractions, getSuppliers } from '../store/storeFunctions';
import {
  defaultFiltersInteractions,
  defaultFiltersSuppliers,
} from '../utils/constants';
import SupplierItem from './suppliers/SupplierItem';

const SearchResults = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();

  const {
    search,
    interactions,
    suppliers,
    supplierPagination,
    isLoading,
    user,
    procurementFilterData,
  } = useSelector((state) => state.appSlice);
  const { setPage, setSearch, setFiltersInteractions, setFiltersSuppliers } =
    appSlice.actions;

  useEffect(() => {
    document.title = 'PartnerElement | Search Results';

    setSearchParams({});
  }, []);

  useEffect(() => {
    if (!procurementFilterData?.internal_stakeholders || !user) return;

    dispatch(
      getInteractions({
        search_query: search,
        procurement_members: generateEmployeeFilters(
          user,
          procurementFilterData?.internal_stakeholders,
          [],
        ),
      }),
    );
    dispatch(
      getSuppliers({
        search_query: search,
        procurement_members: generateEmployeeFilters(
          user,
          procurementFilterData?.internal_stakeholders,
          [],
        )
      }),
    );
  }, [search, user, procurementFilterData]);

  if (isLoading) {
    return (
      <Box sx={{ margin: '24px 180px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {[0, 1, 2, 3].map((_, index) => (
            <LoadingSkeleton variant={'interactionItem'} key={index} />
          ))}
        </Box>
      </Box>
    );
  }

  if (interactions.length === 0 && suppliers.length === 0) {
    return (
      <Box sx={{ height: '422px' }}>
        <NoResultCard variant={'search'} />
      </Box>
    );
  }

  function formatText(count, label) {
    const plural = count !== 1;
    if (!count || !label) return '';

    return `There ${plural ? 'are' : 'is'} ${count} ${
      label + (plural ? 's' : '')
    } matching "${search}"`;
  }

  function handleSeeAll(type) {
    if (type === 'interactions')
      dispatch(
        setFiltersInteractions({
          ...defaultFiltersInteractions,
          timeframe: 'alltime',
          renewals: 'null',
          deal_closed: 'null',
        }),
      );
    if (type === 'suppliers')
      dispatch(setFiltersSuppliers({...defaultFiltersSuppliers, active_account: 'null'}));
    window.scrollTo(0, 0);
    navigate(`/${type}?q=${search}`);
    dispatch(setSearch(''));
    dispatch(setPage(type));
  }

  return (
    <Box sx={{ margin: '24px 180px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6" color="main">
          Interactions
        </Typography>
        <Typography variant="body1" color="text.caption">
          {formatText(interactions?.length || 0, 'interaction')}
        </Typography>
        {interactions.slice(0, 3).map((interaction) => (
          <InteractionItem key={interaction.id} interaction={interaction} />
        ))}
        {Boolean(interactions.length > 3) && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Link
              color="secondary"
              sx={{ cursor: 'pointer' }}
              underline="hover"
              onClick={() => {
                handleSeeAll('interactions');
              }}
            >
              See all interactions
            </Link>
          </Box>
        )}
        <Typography variant="h6" color="main">
          Suppliers
        </Typography>
        <Typography variant="body1" color="text.caption">
          {formatText(supplierPagination?.total_count || 0, 'supplier')}
        </Typography>
        {suppliers.slice(0, 3).map((supplier) => (
          <SupplierItem key={supplier.id} supplier={supplier} />
        ))}
        {Boolean(suppliers.length > 3) && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            marginBottom={'24px'}
          >
            <Link
              color="secondary"
              sx={{ cursor: 'pointer' }}
              underline="hover"
              onClick={() => {
                handleSeeAll('suppliers');
              }}
            >
              See all suppliers
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchResults;
