import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import NoResultCard from '../../common/NoResultCard';
import { getSupplierAlternativeDemoData } from '../../utils/supplierAlternativeDemoData';
import SupplierAlternativeItem from './SupplierAlternativeItem';

const SupplierAlternatives = ({ supplierId, variant, handleClick }) => {
  const [alternatives, setAlternatives] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const alternatives = getSupplierAlternativeDemoData(supplierId);
    setAlternatives(alternatives);
    setLoading(false);
  }, [supplierId]);

  if (loading) {
    return <LoadingSkeleton variant="stats" />;
  }

  if (!alternatives.length) {
    return <NoResultCard variant="supplier-alternatives" />;
  }

  return (
    <Box maxWidth={variant === 'sidebar' ? '632px' : '896px'}>
      <Box
        display={'flex'}
        gap={'4px'}
        flexDirection={'column'}
        paddingBottom={'16px'}
      >
        <Box
          fontSize={(theme) => theme.typography.h6}
          color={(theme) => theme.palette.text.primary}
        >
          Existing alternatives
        </Box>
        <Box
          color={(theme) => theme.palette.text.secondary}
          fontSize={(theme) => theme.typography.body1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            fontSize={(theme) => theme.typography.body1}
            color={(theme) => theme.palette.text.caption}
          >
            {alternatives.length} alternatives found.
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '16px',
        }}
      >
        {alternatives
          .sort((a, b) => b.total_spend - a.total_spend)
          .map((item) => (
            <Box width={'100%'} key={item.id} onClick={() => handleClick()}>
              <SupplierAlternativeItem
                supplier={item}
                sidebar={variant === 'sidebar'}
              />
            </Box>
          ))}
        {alternatives.length === 0 && (
          <NoResultCard variant="supplier-alternatives" />
        )}
      </Box>
    </Box>
  );
};

export default SupplierAlternatives;
