import {
  ArrowForwardRounded,
  ChangeCircleRounded,
  CheckCircleRounded,
  LanOutlined,
  MailOutlineRounded,
  TaskRounded,
  ViewTimelineOutlined,
  VisibilityOffRounded,
} from '@mui/icons-material';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from './CustomCard';
import { stageVariant } from './CustomChip';
import Logo from './Logo';
import StyledIcon from './StyledIcon';

const InteractionItem = ({ interaction, variant }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  let linkUrl =
    variant === 'Details' || variant === 'DetailsFullscreen'
      ? `/details/interaction/${interaction.id}${search}`
      : `details/interaction/${interaction.id}${search}`;

  if (variant === 'Details' || variant === 'DetailsFullscreen' || variant === 'Dashboard') {
    return (
      <CustomCard isHover={true} onClick={() => navigate(linkUrl)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
            alignItems: 'center',
            borderBottom: '1px solid #DEDBEC',
          }}
        >
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Box fontSize={(theme) => theme.typography.subtitle1}>
              {interaction.uid}
            </Box>
            {interaction.deprioritized && (
              <StyledIcon
                component={VisibilityOffRounded}
                color={'icon'}
                sx={{ fontSize: 20 }}
              />
            )}
          </Box>
          <Stack
            width={'50%'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              color={(theme) => theme.palette.text.medium}
              fontSize={(theme) => theme.typography.body2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              {interaction.procurement_involved && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                >
                  <StyledIcon
                    component={CheckCircleRounded}
                    color={'icon_success'}
                    sx={{ fontSize: 20 }}
                  />
                  <Box>Procurement engaged</Box>
                </Box>
              )}
              {interaction.renewal && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                >
                  <StyledIcon
                    component={ChangeCircleRounded}
                    color={'icon_warning'}
                    sx={{ fontSize: 20 }}
                  />
                  <Box>Renewal</Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'end', width: '120px' }}
            >
              <IconButton
                type="button"
                sx={{ padding: '8px' }}
                onClick={() => navigate(linkUrl)}
              >
                <StyledIcon component={ArrowForwardRounded} color={'icon'} />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ padding: '16px', display: 'flex' }}>
          <Box sx={{ width: '50%' }}>
            <Box
              color={(theme) => theme.palette.text.main}
              sx={{
                marginBottom: '8px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <StyledIcon component={LanOutlined} color={'icon'} />
              <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                {interaction.departments_involved.map((item, index) => (
                  <Box key={index} color={(theme) => theme.palette.text.main}>
                    {item}
                    {index < interaction.departments_involved.length - 1 && ','}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box fontSize={(theme) => theme.typography.body2}>
              <Box
                component={'span'}
                color={(theme) => theme.palette.text.medium}
              >
                Start date:{' '}
              </Box>
              <Box
                component={'span'}
                color={(theme) => theme.palette.text.main}
              >
                {moment(interaction.start_time).format('MMM DD, YYYY')}
              </Box>
            </Box>
          </Box>
          {interaction.deal_closed ? (
            <Box sx={{ width: '50%' }}>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body1}
                sx={{
                  marginBottom: '8px',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <StyledIcon
                  component={TaskRounded}
                  color={'secondary'}
                  sx={{ fontSize: '20px' }}
                />
                Deal closed
              </Box>
              <Box fontSize={(theme) => theme.typography.body2}>
                <Box
                  component={'span'}
                  color={(theme) => theme.palette.text.medium}
                >
                  Closing date:{' '}
                </Box>
                <Box
                  component={'span'}
                  color={(theme) => theme.palette.text.main}
                >
                  {moment(interaction.end_time).format('MMM DD, YYYY')}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box sx={{ width: '50%' }}>
              <Box
                color={(theme) => theme.palette.text.main}
                fontSize={(theme) => theme.typography.body1}
                sx={{
                  marginBottom: '8px',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <StyledIcon component={ViewTimelineOutlined} color={'icon'} />
                Stage
              </Box>
              <Chip
                label={interaction.stage_name}
                size={'small'}
                sx={{
                  ...stageVariant(interaction.stage_name),
                }}
              />
            </Box>
          )}
        </Box>
      </CustomCard>
    );
  }

  return (
    <CustomCard isHover={true} onClick={() => navigate(linkUrl)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px',
          alignItems: 'center',
          borderBottom: '1px solid #DEDBEC',
        }}
      >
        <Box
          sx={{
            width: 'calc(100% / 3)',
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <Logo size={40} img={interaction.supplier_image_url} />
          <Box>
            <Box
              color={(theme) => theme.palette.text.main}
              sx={{
                lineHeight: '150%',
              }}
              fontSize={(theme) => theme.typography.subtitle1}
            >
              {interaction.supplier_name}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Box
                color={(theme) => theme.palette.text.medium}
                fontSize={(theme) => theme.typography.caption}
              >
                ID:{interaction.uid}
              </Box>
              {interaction.deprioritized && (
                <StyledIcon
                  component={VisibilityOffRounded}
                  color={'icon'}
                  sx={{ fontSize: 20 }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          color={(theme) => theme.palette.text.medium}
          fontSize={(theme) => theme.typography.body2}
          sx={{
            width: 'calc(100% / 3)',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          {interaction.procurement_involved && (
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              <StyledIcon
                component={CheckCircleRounded}
                color={'icon_success'}
                sx={{ fontSize: 20 }}
              />
              <Box>Procurement engaged</Box>
            </Box>
          )}
          {interaction.renewal && (
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              <StyledIcon
                component={ChangeCircleRounded}
                color={'icon_warning'}
                sx={{ fontSize: 20 }}
              />
              <Box>Renewal</Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            width: 'calc(100% / 3)',
          }}
        >
          <IconButton
            type="button"
            sx={{ padding: '8px' }}
            onClick={() => navigate(linkUrl)}
          >
            <StyledIcon component={ArrowForwardRounded} color={'icon'} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ padding: '16px', display: 'flex' }}>
        <Box sx={{ width: 'calc(100% / 3)' }}>
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <StyledIcon component={MailOutlineRounded} color={'icon'} />
            {interaction.num_emails} messages over {interaction.num_days} days
          </Box>
          <Box fontSize={(theme) => theme.typography.body2}>
            <Box
              component={'span'}
              color={(theme) => theme.palette.text.medium}
            >
              Start date:{' '}
            </Box>
            <Box component={'span'} color={(theme) => theme.palette.text.main}>
              {interaction.start_time &&
                moment(interaction.start_time).format('MMM DD, YYYY')}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: 'calc(100% / 3)' }}>
          <Box
            color={(theme) => theme.palette.text.main}
            sx={{
              marginBottom: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <StyledIcon component={LanOutlined} color={'icon'} />
            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              {interaction.departments_involved.map((item, index) => (
                <Box key={index} color={(theme) => theme.palette.text.main}>
                  {item}
                  {index < interaction.departments_involved.length - 1 && ','}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            color={(theme) => theme.palette.text.medium}
            fontSize={(theme) => theme.typography.body2}
          >
            Most active:{' '}
            <Box component={'span'} sx={{ mr: 4 }}>
              <Box
                component={'span'}
                color={(theme) => theme.palette.text.main}
              >
                {interaction?.most_active_stakeholder?.replace(',', ', ')}
              </Box>
            </Box>
          </Box>
        </Box>
        {interaction.deal_closed ? (
          <Box sx={{ width: 'calc(100% / 3)' }}>
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.body1}
              sx={{
                marginBottom: '8px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <StyledIcon
                component={TaskRounded}
                color={'secondary'}
                sx={{ fontSize: '20px' }}
              />
              Deal closed
            </Box>
            <Box fontSize={(theme) => theme.typography.body2}>
              <Box
                component={'span'}
                color={(theme) => theme.palette.text.medium}
              >
                Closing date:{' '}
              </Box>
              <Box
                component={'span'}
                color={(theme) => theme.palette.text.main}
              >
                {moment(interaction.end_time).format('MMM DD, YYYY')}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: 'calc(100% / 3)' }}>
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) => theme.typography.body1}
              sx={{
                marginBottom: '8px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <StyledIcon component={ViewTimelineOutlined} color={'icon'} />
              Stage
            </Box>
            <Chip
              label={interaction.stage_name}
              size={'small'}
              sx={{
                ...stageVariant(interaction.stage_name),
              }}
            />
          </Box>
        )}
      </Box>
    </CustomCard>
  );
};

export default InteractionItem;
